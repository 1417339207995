import React, { FunctionComponent, useCallback, useState } from "react";
import { Text, Button, Card, Logo, Input } from "shared";
import { Colors } from "theme/color";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  userLogin,
  resendVerify,
  userLoginEmailVerification,
  userOtpStatus,
} from "reducers/user";
import useAppDispatch from "hooks/useAppDispatch";
import { Formik } from "formik";
import * as yup from "yup";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";

import { Container } from "./style";

import BattleTheme from "assets/battle-theme-img.jpg";

const SignInSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

type Props = Record<string, never>;

const SignIn: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const siteKey = process.env.REACT_APP_SITE_KEY!;
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const verifyEmail = useCallback(async () => {
    dispatch(resendVerify())
      .unwrap()
      .then(() => {
        // If the API request is successful, set `isVisible` to `true`
        navigate("/email-verification");
      })
      .catch((error) => {
        // If the API request fails, handle the error here
        console.error(error);
      });
  }, [dispatch]);

  const handleSignIn = useCallback(
    async (values: { email: string; password: string }) => {
      try {
        const captchaValue = recaptchaRef.current?.getValue();
        if (!captchaValue) {
          swal({
            title: "Sign In",
            text: "Please verify the reCAPTCHA!",
            icon: "warning",
          });
          return; // Exit function if any field is empty
        }

        setIsLoading(true);
        setIsDisabled(true);

        // attempt a login, and if successful get the user profile
        const result = await dispatch(
          userLogin({
            email: values.email,
            password: values.password,
          })
        ).unwrap();
        {
          const checkEmailVerification = await dispatch(
            userLoginEmailVerification({
              email: values.email,
            })
          ).unwrap();
          const checkEmailOtpStatus = await dispatch(userOtpStatus()).unwrap();

          if (checkEmailOtpStatus.is_otp_enabled === false) {
            navigate("/multi-factor-enable");
          } else if (checkEmailOtpStatus.is_otp_enabled) {
            navigate("/multi-factor-authentication");
          } else if (checkEmailVerification.isVerified) {
            navigate("/dashboard");
          } else if (result.response.is_invited_user) {
            navigate("/task-board");
          } else {
            swal({
              title: "Email Verification",
              text: "Please verify your email to continue.",
              icon: "warning",
              buttons: {
                confirm: {
                  text: "Verify Email",
                  value: true,
                  visible: true,
                  className: "btn-primary",
                  closeModal: true,
                },
                cancel: {
                  text: "Cancel",
                  value: null,
                  visible: true,
                  className: "btn-secondary",
                  closeModal: true,
                },
              },
            }).then((value) => {
              if (value) {
                // Redirect to the desired page
                verifyEmail();
              }
            });
          }
        }
      } catch (error: any) {
        if (error?.data.status === 400) {
          swal({
            title: "Login",
            text: "Bad Request",
            icon: "error",
          });
        } else if (error?.data.status === 401) {
          swal({
            title: "Login",
            text: "Wrong email or password",
            icon: "error",
          });
        } else {
          swal({
            title: "Login",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      } finally {
        setIsLoading(false);
        setIsDisabled(false);
      }
    },
    [dispatch]
  );

  return (
    <>
      <Box>
        <Grid container className="background-image" alignItems="center">
          <Grid item xs={12} md={6} alignItems="center">
            <Container
              p={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              pl={{ lg: 8, md: 6 }}
              pr={{ lg: 8, md: 6 }}
            >
              <Card>
                <Box p={{ xs: 2, sm: 2, md: 6, lg: 8 }}>
                  <Logo />
                  <Box>
                    <Formik
                      initialValues={{
                        password: "",
                        email: "",
                      }}
                      validationSchema={SignInSchema}
                      onSubmit={handleSignIn}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <Box>
                          <Input
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={values.email}
                            onChange={handleChange("email")}
                          />
                          {errors.email && touched.email ? (
                            <div className="error-message">{errors.email}</div>
                          ) : null}
                          <Input
                            name="password"
                            placeholder="Password"
                            value={values.password}
                            onChange={handleChange("password")}
                            type={showPassword ? "text" : "password"}
                            handleShow={toggleShowPassword}
                            Icon={
                              showPassword ? <Visibility /> : <VisibilityOff />
                            }
                          />
                          {errors.password && touched.password ? (
                            <div className="error-message">
                              {errors.password}
                            </div>
                          ) : null}
                          <Box pt={2} ml={1}>
                            <Text color={Colors.text.link} fontSize="16">
                              <Link
                                to="/forget-password"
                                style={{
                                  textDecoration: "none",
                                  color: Colors.text.link,
                                }}
                              >
                                Forgot password?123fahad
                              </Link>
                            </Text>
                          </Box>
                          <Box
                            mt={2}
                            display="flex"
                            justifyContent="center"
                            width="100%"
                          >
                            <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} />
                          </Box>
                          <Box pt={6}>
                            <Button
                              title={isLoading ? "Loading..." : "Log In"}
                              align="center"
                              onClick={handleSubmit}
                              disabled={isDisabled}
                            />
                          </Box>
                        </Box>
                      )}
                    </Formik>
                    <Box
                      pt={2}
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      <Link to="/signup" style={{ textDecoration: "none" }}>
                        <Stack
                          direction={{ xs: "row", sm: "row" }}
                          spacing={{ xs: 0, sm: 0, md: 0 }}
                        >
                          <Text
                            color={Colors.text.black}
                            fontSize="14"
                            variant="subtitle1"
                          >
                            Don’t have an account?{" "}
                            <Typography component="span" className="creat-link">
                              Create now
                            </Typography>
                          </Text>
                        </Stack>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Container>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display={{ xs: "none", md: "block", lg: "block" }}
            alignItems="center"
          >
            <Box>
              <img
                src={BattleTheme}
                alt="battle-theme"
                className="battle-theme-img"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SignIn;
