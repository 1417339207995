import React, { FunctionComponent, useEffect, useState } from "react";
import { DashboardWrapper, Text, Loader, CustomeButton } from "shared";
import { Grid, Box, Link, Button } from "@mui/material";
import Logo from "assets/CMMCProofLogo.png";
import { Colors } from "theme/color";
import CheckOne from "assets/check.png";
import CheckTwo from "assets/check-2.png";
import Standard from "assets/standard.png";
import Premium from "assets/premium.png";
import Basic from "assets/basic.png";
import { getPackageList } from "reducers/assessment";
import {
  userPackages,
  subscribeFrontlineDefender,
  cancelSubscription,
  reActivateSubscription,
} from "reducers/user";
import useAppDispatch from "hooks/useAppDispatch";
import { RootState } from "store";
import { useSelector } from "react-redux";
import {
  LogoBox,
  CustomAvatar,
  PackageType,
  CustomTick,
  Card,
  SubscriptionMessage,
} from "./style";
import { FortressPackage, Bunker, FrontlineDefender } from "utils/constant";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type Props = Record<string, never>;

const Billing: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const packageList = useSelector(
    (state: RootState) => state.assessment.packageList
  );

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const activePackageID = activePackage?.length > 0 && activePackage[0].id;
  const activePackageStatus =
    activePackage?.length > 0 && activePackage[0].stripe_status;

  const handleCancelSubscription = () => {
    setIsCancel(true);
    dispatch(
      cancelSubscription({
        plan_id: activePackageID,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(userPackages())
          .unwrap()
          .then(() => {
            setIsCancel(false);
            setOpen(false);
          })
          .catch((err: any) => {
            console.log(err.message);
            setIsCancel(false);
            setOpen(false);
          });

        swal({
          title: "Subscription",
          text: `Your subscription canceled successfully!`,
          icon: "success",
        });
      })
      .catch((err: any) => {
        console.log(err);
        setIsCancel(false);
        setOpen(false);
        swal({
          title: "Subscription",
          text: `Something went wrong. Please try again!`,
          icon: "error",
        });
      });
  };

  const handleReactivationSubscription = () => {
    setIsSubmitting(true);

    dispatch(
      reActivateSubscription({
        plan_id: activePackageID,
      })
    )
      .unwrap()
      .then(() => {
        // Show success alert
        swal({
          title: "Subscription",
          text: `Your subscription re-activated successfully!`,
          icon: "success",
        });
        setIsLoading(true);
        // Set timeout to call userPackages after 10 seconds
        setTimeout(() => {
          dispatch(userPackages())
            .unwrap()
            .then(() => {
              setIsSubmitting(false);
              setIsLoading(false);
            })
            .catch((err: any) => {
              console.log(err.message);
              setIsSubmitting(false);
              setIsLoading(false);
            });
        }, 10000); // 10 seconds delay
      })
      .catch((err: any) => {
        console.log(err);
        setIsSubmitting(false);
        setIsLoading(false);
        swal({
          title: "Subscription",
          text: `Something went wrong. Please try again!`,
          icon: "error",
        });
      });
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(getPackageList())
      .unwrap()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [dispatch]);

  const handleSubscribeFronLinePackage = () => {
    setIsLoading(true);
    dispatch(
      subscribeFrontlineDefender({
        plan_id: 1,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(userPackages())
          .unwrap()
          .then(() => {
            setIsLoading(false);
          })
          .catch((err: any) => {
            console.log(err.message);
            setIsLoading(false);
          });

        navigate("/dashboard");
        swal({
          title: "Subscription",
          text: `Congratulation you have subsribe Frontline Defender successfully !`,
          icon: "success",
        });
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
        swal({
          title: "Subscription",
          text: `Something went wrong. Please try again!`,
          icon: "error",
        });
      });
  };

  return (
    <DashboardWrapper>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Box mt={3}>
          <Box display="flex" justifyContent="center">
            {activePackageStatus?.status === "canceled" && (
              <SubscriptionMessage
                onClick={handleReactivationSubscription}
                sx={{
                  backgroundColor: isSubmitting ? "grey" : "#323232",
                  color: isSubmitting ? "#000" : "#ffffff",
                }}
              >
                {activePackageStatus.message}
              </SubscriptionMessage>
            )}
          </Box>
          <Grid container spacing={2} mt={4}>
            {packageList?.map((item, index) => (
              <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                <Card
                  backgroundColor={
                    item.name === FortressPackage
                      ? Colors.text.black
                      : Colors.text.white
                  }
                  pt={3}
                >
                  <Card
                    backgroundColor={
                      item.name === FortressPackage
                        ? Colors.button.primary_bg
                        : Colors.text.plan_bg_1
                    }
                    p={3}
                  >
                    <Box display="flex">
                      {item.name === FrontlineDefender && (
                        <PackageType src={Basic} alt="Basic" />
                      )}
                      {item.name === Bunker && (
                        <PackageType src={Standard} alt="Standard" />
                      )}
                      {item.name === FortressPackage && (
                        <PackageType src={Premium} alt="Premium" />
                      )}

                      {currentPackage === item.name && (
                        <Box
                          sx={{
                            background:
                              item.name === FortressPackage
                                ? Colors.text.black
                                : Colors.button.primary_bg,
                            width: "fit-content",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            marginLeft: "10px",
                            fontSize: "14px",
                            color:
                              item.name === FortressPackage
                                ? Colors.text.white
                                : Colors.text.black,
                            textTransform: "capitalize",
                          }}
                        >
                          {activePackageStatus?.status}
                        </Box>
                      )}
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <LogoBox>
                        <CustomAvatar alt="Remy Sharp" src={Logo} />
                      </LogoBox>
                    </Box>
                    <Text
                      fontSize="20px"
                      fontWeight="600"
                      align="center"
                      color={
                        item.name === FortressPackage
                          ? Colors.text.black
                          : Colors.text.theme
                      }
                    >
                      {item.name !== FortressPackage && item.name}
                    </Text>
                    <Box mt={2}>
                      <Text
                        fontSize="18px"
                        fontWeight="500"
                        align="center"
                        color={
                          item.name === FortressPackage
                            ? Colors.text.black
                            : Colors.text.theme
                        }
                      >
                        {item.package_sub_name}
                      </Text>
                    </Box>
                    <Box mt={3} mb={3}>
                      <Text
                        fontSize="24px"
                        fontWeight="600"
                        align="center"
                        color={
                          item.name === FortressPackage
                            ? Colors.text.black
                            : Colors.text.white
                        }
                      >
                        {item.price_text}
                      </Text>
                      <Text
                        fontSize="14px"
                        fontWeight="300"
                        align="center"
                        color={
                          item.name === FortressPackage
                            ? Colors.text.black
                            : Colors.text.light_grey
                        }
                      >
                        {item.description}
                      </Text>
                    </Box>
                    <Box pt={item.name === FortressPackage ? 1.5 : 0}>
                      {item.features.map((feature: any, index: any) => (
                        <Box
                          display="flex"
                          pt={item.name === FortressPackage ? 1 : 3}
                          key={index}
                        >
                          <Box>
                            {item.name === FortressPackage ? (
                              <CustomTick src={CheckTwo} />
                            ) : (
                              <CustomTick src={CheckOne} />
                            )}
                          </Box>
                          <Box ml={3}>
                            <Text
                              fontSize="18px"
                              fontWeight="400"
                              color={
                                item.name === FortressPackage
                                  ? Colors.text.black
                                  : Colors.text.white
                              }
                            >
                              {feature.description}
                            </Text>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    {item.name === FrontlineDefender ? (
                      <Box mt={14}>
                        <CustomeButton
                          title={
                            currentPackage === item.name
                              ? "Activated"
                              : item.name === FrontlineDefender
                              ? "Unlock Access"
                              : "Start Free 7-Day Trial"
                          }
                          borderRadius="10px"
                          fontWeight="500"
                          fontSize="18px"
                          align="center"
                          width="80%"
                          background={
                            item.name === FortressPackage
                              ? "#000"
                              : Colors.button.primary_bg
                          }
                          color={
                            item.name === FortressPackage
                              ? Colors.text.white
                              : Colors.text.black
                          }
                          onClick={handleSubscribeFronLinePackage}
                        />
                      </Box>
                    ) : activePackageStatus?.status !== "canceled" ? (
                      <Box mt={4}>
                        <Link
                          href={`${item?.payment_link}?prefilled_email=${
                            userInfo?.email || ""
                          }`}
                          style={{ textDecoration: "none" }}
                        >
                          <CustomeButton
                            title={
                              currentPackage === item.name
                                ? "Activated"
                                : item.name === FrontlineDefender
                                ? "Unlock Access"
                                : "Start Free 7-Day Trial"
                            }
                            borderRadius="10px"
                            fontWeight="500"
                            fontSize="18px"
                            align="center"
                            width="80%"
                            background={
                              item.name === FortressPackage
                                ? "#000"
                                : Colors.button.primary_bg
                            }
                            color={
                              item.name === FortressPackage
                                ? Colors.text.white
                                : Colors.text.black
                            }
                          />
                        </Link>
                      </Box>
                    ) : (
                      <Box mt={4}>
                        <CustomeButton
                          onClick={handleReactivationSubscription}
                          disabled={isSubmitting}
                          title={
                            currentPackage === item.name
                              ? "Reactivate"
                              : item.name === FrontlineDefender
                              ? "Unlock Access"
                              : "Start Free 7-Day Trial"
                          }
                          borderRadius="10px"
                          fontWeight="500"
                          fontSize="18px"
                          align="center"
                          width="80%"
                          background={
                            item.name === FortressPackage
                              ? "#000"
                              : Colors.button.primary_bg
                          }
                          color={
                            item.name === FortressPackage
                              ? Colors.text.white
                              : Colors.text.black
                          }
                        />
                      </Box>
                    )}
                    {currentPackage === item.name &&
                      item.name !== FrontlineDefender &&
                      activePackageStatus?.status !== "canceled" && (
                        <Box display="flex" justifyContent="center" mt={1}>
                          <Button
                            variant="contained"
                            onClick={handleClickOpen}
                            sx={{
                              background: Colors.text.grey,
                              textTransform: "capitalize",
                              color: Colors.text.white,
                              height: 48,
                              width: "80%",
                              borderRadius: "10px",
                              fontSize: "17px",
                            }}
                          >
                            Cancel Subscription
                          </Button>
                          <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            BackdropProps={{
                              sx: {
                                bgcolor: "rgba(0, 0, 0, 0.4)",
                              },
                            }}
                          >
                            <DialogTitle
                              id="alert-dialog-title"
                              sx={{
                                fontSize: "22px",
                                fontWeight: 600,
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            >
                              Cancel Subscription
                            </DialogTitle>
                            <DialogContent
                              sx={{ marginLeft: "10px", marginRight: "10px" }}
                            >
                              <DialogContentText id="alert-dialog-description">
                                Are you sure you want to cancel your
                                subscription plan? This action will lock all its
                                features, and you will no longer have access.
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions
                              sx={{ marginBottom: "10px", marginTop: "10px" }}
                            >
                              <Button
                                onClick={handleClose}
                                variant="contained"
                                sx={{
                                  background: Colors.text.light_grey,
                                  textTransform: "capitalize",
                                  fontSize: "18px",
                                  width: "120px",
                                  "&:hover": {
                                    background: Colors.text.sky_1,
                                  },
                                }}
                              >
                                Disagree
                              </Button>
                              <Button
                                disabled={isCancel}
                                onClick={handleCancelSubscription}
                                autoFocus
                                variant="contained"
                                sx={{
                                  background: Colors.text.red,
                                  textTransform: "capitalize",
                                  fontSize: "18px",
                                  width: "120px",
                                  "&:hover": {
                                    background: Colors.text.light_red,
                                  },
                                }}
                              >
                                Agree
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Box>
                      )}
                  </Card>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </DashboardWrapper>
  );
};

export default Billing;
