import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { PURGE } from "redux-persist";

import * as api from "services/api";
import { RootState } from "store";

export interface AuthState {
  id: any;
  assessmentId: any;
  recentHistory: any;
  loading: boolean;
  assessmentsListData: any[];
  assessmentsQaList: any[];
  sspList: any[];
  assessmentReportList: any[];
  objectives: any;
  totalObjectives: any;
  userResponse: any;
  evidenveListData: any;
  evidenveListImages: string[];
  evidenceList: any[];
  has_next: boolean;
  assetListData: any[];
  categoryListData: any[];
  poam: any[];
  poamList: any;
  milestonesProgress: any;
  bufferListData: any[];
  assessments: Record<number, number>;
  packageList: any[];
  comments: Record<string, Record<string, string>>;
}

const initialState: AuthState = {
  id: null,
  assessmentId: null,
  recentHistory: {},
  loading: false,
  assessmentsListData: [],
  assessmentsQaList: [],
  sspList: [],
  assessmentReportList: [],
  objectives: null,
  totalObjectives: null,
  userResponse: null,
  evidenveListData: {},
  evidenveListImages: [],
  evidenceList: [],
  has_next: false,
  assetListData: [],
  categoryListData: [],
  poam: [],
  poamList: null,
  milestonesProgress: null,
  bufferListData: [],
  assessments: {},
  packageList: [],
  comments: {},
};

export const addAssessment = createAsyncThunk(
  "assessment/create",
  async (
    options: {
      assessment: string;
      companyName: string;
      assessmentType: string;
      assessmentLevel: any;
      target_assessment_level?: any;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.addAssessment(
        options.assessment,
        options.companyName,
        options.assessmentType,
        options.assessmentLevel,
        options.target_assessment_level
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchAssessments = createAsyncThunk(
  "assessment/list",
  async (
    {
      page,
      limit,
      levelId,
      search,
    }: { page: number; limit: number; levelId: string; search: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.fetchAssessmentList(
        page,
        limit,
        levelId,
        search
      );
      const count = response.count;
      return { response, count };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteAssessment = createAsyncThunk(
  "assessment/delete",
  async (
    options: {
      id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.deleteAssessment(options.id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchAssessmentStats = createAsyncThunk(
  "assessment/stats",
  async (
    {
      inprogressPage,
      completedPage,
      perPage,
    }: { inprogressPage: number; completedPage: number; perPage: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.fetchAssessmentStats(
        inprogressPage,
        completedPage,
        perPage
      );
      const completed = response.completed;
      const inprogress = response.inprogress;
      return {
        response,
        completed,
        inprogress,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAssessmentLevels = createAsyncThunk(
  "assessment/levels",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.assessmentLevels();

      return { response };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAssessmentQaList = createAsyncThunk(
  "assessment/level-qa",
  async (
    {
      assessmentId,
      levelId,
      page,
    }: { assessmentId: any; levelId: any; page?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.getAssessmentQa(assessmentId, levelId, page);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSSpAssessmentSSpList = createAsyncThunk(
  "assessment/ssp-creator",
  async ({ assessmentId }: { assessmentId: any }, { rejectWithValue }) => {
    try {
      const response = await api.getSSpAssessment(assessmentId);

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAssessmentReport = createAsyncThunk(
  "assessment/report",
  async ({ assessmentId }: { assessmentId: any }, { rejectWithValue }) => {
    try {
      const response = await api.getAssessmentReport(assessmentId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addEvidence = createAsyncThunk(
  "assessment/create-evidence",
  async (
    options: {
      pk: string;
      assessmentId: number | null;
      assessmentObjective: number | null;
      name: string;
      additionalNotes: string;
      evidenceDate: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.addEvidence(
        options.pk,
        options.assessmentId,
        options.assessmentObjective,
        options.name,
        options.additionalNotes,
        options.evidenceDate
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getEvidenceReport = createAsyncThunk(
  "assessment/assessment-evidence",
  async (
    {
      assessmentId,
      objectiveId,
    }: { assessmentId: number | null; objectiveId: number | null },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.getEvidence(assessmentId, objectiveId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getEvidenceImagesReport = createAsyncThunk(
  "assessment/get-all-assessment-evidence-media",
  async ({ assessmentId }: { assessmentId: any }, { rejectWithValue }) => {
    try {
      const response = await api.getEvidenceImagesList(assessmentId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const evidenceImage = createAsyncThunk(
  "assessment/add-evidence-media",
  async (
    options: {
      assessment_evidence: number | null;
      image?: File | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.evidenceImage(
        options.assessment_evidence,
        options.image
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const putEvidenceImage = createAsyncThunk(
  "assessment/add-evidence-media",
  async (
    options: {
      evidence_image_id: string | null;
      assessment_evidence: number | null;
      image?: File | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.putEvidenceImage(
        options.evidence_image_id,
        options.assessment_evidence,
        options.image
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteEvidence = createAsyncThunk(
  "assessment/delete-evidence-media",
  async (
    options: {
      pk: number;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.deleteEvidence(options.pk);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchEvidenceList = createAsyncThunk(
  "assessment/list-evidence",
  async (
    { page, limit, search }: { page: number; limit: number; search: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.fetchEvidenceList(page, limit, search);
      const assessmentsListData = response.list;
      const count = response.count;
      return { response, assessmentsListData, count };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const userQaResponse = createAsyncThunk(
  "assessment/store-qa-response",
  async (
    options: {
      answer: string;
      assessment: string;
      assessment_objective: any;
      task: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.storeQaResponse(
        options.answer,
        options.assessment,
        options.assessment_objective,
        options.task
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const undoQaResponse = createAsyncThunk(
  "assessment/undo-qa-response",
  async (
    options: {
      answer: string;
      assessment: string;
      assessment_objective: string;
      task: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.undoQaResponse(
        options.answer,
        options.assessment,
        options.assessment_objective,
        options.task
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchAssetList = createAsyncThunk(
  "asset_inventory/inventory",
  async (
    { page, limit, search }: { page: number; limit: number; search: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.fetchAssetList(page, limit, search);
      const count = response.count;
      return { response, count };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addAsset = createAsyncThunk(
  "asset_inventory/inventory/",
  async (
    options: {
      category: string;
      asset: string;
      quantity: string;
      assetType: string;
      assetDescription: string;
      comment: string;
      controlledBy?: string;
      poc?: string;
      cuiCategory?: string;
      distributionControl?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.addAsset(
        options.category,
        options.asset,
        options.quantity,
        options.assetType,
        options.assetDescription,
        options.comment,
        options.controlledBy,
        options.poc,
        options.cuiCategory,
        options.distributionControl
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteAsset = createAsyncThunk(
  "/asset_inventory/inventory",
  async (
    options: {
      id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.deleteAsset(options.id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchCategory = createAsyncThunk(
  "asset_inventory/categories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchCategory();
      return { response };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateAsset = createAsyncThunk(
  "asset_inventory/update",
  async (
    options: {
      id: number | undefined;
      category: string;
      asset: string;
      quantity: string;
      assetType: string;
      assetDescription: string;
      comment: string;
      controlledBy?: string;
      poc?: string;
      cuiCategory?: string;
      distributionControl?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateAsset(
        options.id,
        options.category,
        options.asset,
        options.quantity,
        options.assetType,
        options.assetDescription,
        options.comment,
        options.controlledBy,
        options.poc,
        options.cuiCategory,
        options.distributionControl
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPoam = createAsyncThunk(
  "poam",
  async ({ assessmentId }: { assessmentId: any }, { rejectWithValue }) => {
    try {
      const response = await api.getPoam(assessmentId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addPoem = createAsyncThunk(
  "add/poem",
  async (
    options: {
      poamId: string;
      weaknessName: string;
      severity: string;
      assessment: any;
      point_of_contact_email: string;
      scheduled_completion_date: any;
      comments: string;
      source_of_weakness: string;
      weakness_description: string;
      actual_completion_date: any;
      milestones: { milestone: string; is_completed: boolean }[];
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.addPoam(
        options.poamId,
        options.weaknessName,
        options.severity,
        options.assessment,
        options.point_of_contact_email,
        options.scheduled_completion_date,
        options.comments,
        options.source_of_weakness,
        options.weakness_description,
        options.actual_completion_date,
        options.milestones
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPoamList = createAsyncThunk(
  "poam/list",
  async ({ assessmentId }: { assessmentId: any }, { rejectWithValue }) => {
    try {
      const response = await api.getPoamList(assessmentId);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const putPoam = createAsyncThunk(
  "put/poem",
  async (
    options: {
      pk: number;
      poamId: string;
      weaknessName: string;
      severity: string;
      assessment: any;
      point_of_contact_email: string;
      scheduled_completion_date: any;
      comments: string;
      source_of_weakness: string;
      weakness_description: string;
      actual_completion_date: any;
      milestones: { milestone: string; is_completed: boolean }[];
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.putPoam(
        options.pk,
        options.poamId,
        options.weaknessName,
        options.severity,
        options.assessment,
        options.point_of_contact_email,
        options.scheduled_completion_date,
        options.comments,
        options.source_of_weakness,
        options.weakness_description,
        options.actual_completion_date,
        options.milestones
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMailBufferList = createAsyncThunk(
  "buffer/getMailBufferList",
  async (_, { rejectWithValue }) => {
    try {
      return await api.getBufferList();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBufferZoneQuestionWithowner = createAsyncThunk(
  "getBuffer/zone/question",
  async (
    options: {
      id: number;
      ownerPk: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.getBufferZoneQuestionWithowner(
        options.id,
        options.ownerPk
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBufferZoneQuestion = createAsyncThunk(
  "getBuffer/zone/question",
  async (
    options: {
      id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.getBufferZoneQuestion(options.id);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bufferZoneQuestionResponse = createAsyncThunk(
  "buffer-zone/user-buffer-zone-option",
  async (
    options: {
      index: number;
      is_completed: boolean | undefined;
      task?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.bufferZoneQuestionResponse(
        options.index,
        options.is_completed,
        options.task
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBufferZoneQuestionMutions = createAsyncThunk(
  "getBuffer/zone/mutions",
  async (
    options: {
      id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.getBufferZoneQuestionMutions(options.id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPackageList = createAsyncThunk(
  "package/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getPackageList();
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createThreadId = createAsyncThunk(
  "create/thread/id",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.createThreadId();
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addMessage = createAsyncThunk(
  "add/message",
  async (
    { role, content }: { role: string; content: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.addMessage(role, content);
      return { response };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const runThread = createAsyncThunk(
  "run/thread/id",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.runThread();

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const runThreadID = createAsyncThunk(
  "run/id/thread",
  async (
    options: {
      id: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.runThreadID(options.id);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMessageList = createAsyncThunk(
  "message/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getMessageList();
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addCommentNistCsfSubCategory = createAsyncThunk(
  "add/comment/sub/category",
  async (
    options: {
      answer: any;
      assessment: string;
      assessment_objective: any;
      comment: any;
      task: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.addCommentNistCsfSubCaytegory(
        options.answer,
        options.assessment,
        options.assessment_objective,
        options.comment,
        options.task
      );

      return { response };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const featureRequest = createAsyncThunk(
  "add/feature",
  async (
    options: {
      title: any;
      description: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.featureRequest(options.title, options.description);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateHelpTextHtml = createAsyncThunk(
  "tooltip/html/update",
  async (
    options: {
      help_text_id: number | undefined;
      security_requirement: number | undefined;
      assessment: string;
      user_help_text: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateTooltipHtml(
        options.help_text_id,
        options.security_requirement,
        options.assessment,
        options.user_help_text
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    setId(state, action) {
      const newRecentHistory = { ...state.recentHistory };
      newRecentHistory[action.payload.assessmentId] = action.payload.id;
      return { ...state, recentHistory: newRecentHistory };
    },

    setAssessmentId: (
      state,
      action: PayloadAction<{ assessmentId: any; pageNumber: number }>
    ) => {
      const { assessmentId, pageNumber } = action.payload;

      // Ensure state.assessments is initialized
      if (!state.assessments) {
        state.assessments = {};
      }

      state.assessments[assessmentId] = pageNumber;
    },

    addComment(
      state,
      action: PayloadAction<{
        assessmentId: string;
        objectiveId: string;
        comment: string;
      }>
    ) {
      const { assessmentId, objectiveId, comment } = action.payload;

      if (!state.comments[assessmentId]) {
        state.comments[assessmentId] = {};
      }
      state.comments[assessmentId][objectiveId] = comment;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(addAssessment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addAssessment.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addAssessment.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAssessments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAssessments.fulfilled, (state, action) => {
      state.loading = false;
      state.assessmentsListData = action.payload.response.list;
    });
    builder.addCase(fetchAssessments.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteAssessment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAssessment.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAssessment.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteAsset.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAsset.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAsset.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAssessmentStats.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchAssessmentStats.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchAssessmentStats.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAssessmentLevels.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssessmentLevels.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getAssessmentLevels.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAssessmentQaList.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAssessmentQaList.fulfilled, (state, action) => {
      state.loading = false;
      state.has_next = action.payload?.has_next;
      state.assessmentsQaList =
        action.payload?.data?.grouped_security_requirements;
      state.userResponse = action.payload?.data?.total_overall_responses;
      state.totalObjectives = action.payload?.data?.total_overall_objectives;

      const userAnswers: { [key: string]: any } = {};
      action.payload.data?.grouped_security_requirements?.map((item: any) => {
        item.security_requirements.map((obj: any) => {
          if (obj.objectives) {
            obj.objectives.map((objective: any) => {
              userAnswers[objective.pk] = objective.user_answer;
            });
          }
        });
      });

      // Store userAnswers object in the state
      state.objectives = userAnswers;
    });

    builder.addCase(getAssessmentQaList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSSpAssessmentSSpList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSSpAssessmentSSpList.fulfilled, (state, action) => {
      state.loading = false;
      state.sspList = action.payload;
    });
    builder.addCase(getSSpAssessmentSSpList.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAssessmentReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssessmentReport.fulfilled, (state, action) => {
      state.loading = false;
      state.assessmentReportList = action.payload;
    });
    builder.addCase(getAssessmentReport.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addEvidence.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addEvidence.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addEvidence.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getEvidenceReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEvidenceReport.fulfilled, (state, action) => {
      state.loading = false;
      state.evidenveListData = action.payload;
    });
    builder.addCase(getEvidenceReport.rejected, (state) => {
      state.loading = false;

      state.evidenveListData = null; // Fix the property name here
    });
    builder.addCase(getEvidenceImagesReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEvidenceImagesReport.fulfilled, (state, action) => {
      state.loading = false;
      state.evidenveListImages = action.payload;
    });
    builder.addCase(getEvidenceImagesReport.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(evidenceImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(evidenceImage.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(evidenceImage.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteEvidence.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteEvidence.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteEvidence.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchEvidenceList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEvidenceList.fulfilled, (state, action) => {
      state.loading = false;
      state.evidenceList = action.payload.response.list;
    });
    builder.addCase(fetchEvidenceList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(userQaResponse.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(userQaResponse.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(userQaResponse.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(undoQaResponse.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(undoQaResponse.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(undoQaResponse.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchAssetList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAssetList.fulfilled, (state, action) => {
      state.loading = false;
      state.assetListData = action.payload.response.list;
    });
    builder.addCase(fetchAssetList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addAsset.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(addAsset.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addAsset.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.categoryListData = action.payload.response;
    });
    builder.addCase(fetchCategory.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAsset.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAsset.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAsset.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getPoam.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPoam.fulfilled, (state, action) => {
      state.loading = false;
      state.poam = action.payload.grouped_security_requirements;
      state.milestonesProgress = action.payload.milestone_prgress;
    });
    builder.addCase(getPoam.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addPoem.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addPoem.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addPoem.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getPoamList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPoamList.fulfilled, (state, action) => {
      state.loading = false;
      state.poamList = action.payload;
    });
    builder.addCase(getPoamList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(putPoam.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(putPoam.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(putPoam.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getMailBufferList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMailBufferList.fulfilled, (state, action) => {
      state.loading = false;
      state.bufferListData = action?.payload;
    });
    builder.addCase(getMailBufferList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getBufferZoneQuestion.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBufferZoneQuestion.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getBufferZoneQuestion.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(bufferZoneQuestionResponse.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(bufferZoneQuestionResponse.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(bufferZoneQuestionResponse.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getPackageList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPackageList.fulfilled, (state, action) => {
      state.loading = false;
      state.packageList = action.payload;
    });
    builder.addCase(getPackageList.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createThreadId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createThreadId.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createThreadId.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addMessage.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addMessage.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(runThread.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(runThread.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(runThread.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(runThreadID.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(runThreadID.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(runThreadID.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getMessageList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMessageList.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getMessageList.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addCommentNistCsfSubCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCommentNistCsfSubCategory.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addCommentNistCsfSubCategory.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(featureRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(featureRequest.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(featureRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateHelpTextHtml.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateHelpTextHtml.fulfilled, (state, action) => {
      const { security_requirement, user_help_text } = action.payload;

      // Find the security requirement with matching ID and update the user_help_text
      state.assessmentsQaList?.map((item: any) => {
        item.security_requirements.map((sr: any) => {
          if (sr.pk === security_requirement) {
            sr.user_help_text.html = user_help_text;
          }
        });
      });

      state.loading = false;
    });
    builder.addCase(updateHelpTextHtml.rejected, (state) => {
      state.loading = false;
    });

    // when purging reset back to the initial state
    builder.addCase(PURGE, () => initialState);
  },
});
export const { setId, setAssessmentId, addComment } = assessmentSlice.actions;

export default assessmentSlice.reducer;

interface UserSelectorsType {
  loading: boolean;
}

export const UserSelectors = (): UserSelectorsType => {
  const loading = useSelector((state: RootState) => state.user.loading);
  return {
    loading,
  };
};
