import React, { FunctionComponent } from "react";
import { DashboardWrapper, Text, Button } from "shared";
import { Box, Grid } from "@mui/material";
import ThnakuImg from "assets/thanku.png";
import { useNavigate } from "react-router-dom";
import { PackageAvatar, Alignment } from "./style";

type Props = Record<string, never>;

const Thanku: FunctionComponent<Props> = () => {
  const navigate = useNavigate();

  const handleProfile = () => {
    navigate("/profile");
  };

  return (
    <DashboardWrapper>
      <Grid container spacing={2} alignContent="center" justifyContent="center">
        <Grid item lg={9} md={10} sm={12} xs={12}>
          <Alignment>
            <Box display="flex" justifyContent="center" width="100%">
              <PackageAvatar src={ThnakuImg} alt="thankuimg" />
            </Box>
            <Box>
              <Text fontSize="36px" fontWeight="600" align="center">
                Congratulations! You&apos;re One Step Closer to CMMC Compliance!
              </Text>
            </Box>
            <Box>
              <Text fontSize="20px" fontWeight="400" align="center">
                &quot;Welcome to CMMC Proof!Your Free 7-Day subscription is
                active, and you&apos;re now equipped with the tools, resources,
                and expert guidance to Get CMMC compliant ASAP.&quot;
              </Text>
            </Box>
            <Box mt={3}>
              <Button
                title={"Get Started"}
                align="center"
                width="200px"
                fontSize="16px"
                fontWeight="600"
                onClick={handleProfile}
              />
            </Box>
          </Alignment>
        </Grid>
      </Grid>
    </DashboardWrapper>
  );
};

export default Thanku;
