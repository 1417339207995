import React, { useState, useEffect } from "react";
import {
  DashboardWrapper,
  CustomeButton,
  BufferProgress,
  Text,
  AssetTable,
  Loader,
} from "shared";
import { Box, Grid, FormControlLabel, Modal, Button } from "@mui/material";
import { FaAngleDoubleLeft } from "react-icons/fa";
import swal from "sweetalert";
import { ReactComponent as HoverImageOne } from "assets/one-img.svg";
import { ReactComponent as HoverImageTwo } from "assets/two-img.svg";
import { ReactComponent as HoverImageThree } from "assets/three-img.svg";
import { ReactComponent as HoverImageFour } from "assets/four-img.svg";
import { ReactComponent as HoverImageFive } from "assets/five-img.svg";
import { ReactComponent as HoverImageSix } from "assets/six-img.svg";
import { Colors } from "theme/color";
import Checkbox from "@mui/material/Checkbox";

import { ReactComponent as GetAssessmentIcon } from "assets/a-tag.svg";
import {
  BattleActive,
  FortressActive,
  WarActive,
  TacticalActive,
  ReconnaissanceActive,
  IntelligenceActive,
} from "utils/icons";
import { useNavigate, useLocation } from "react-router-dom";
import useAppDispatch from "hooks/useAppDispatch";
import {
  getBufferZoneQuestionWithowner,
  bufferZoneQuestionResponse,
  getBufferZoneQuestionMutions,
} from "reducers/assessment";
import { objectChangeHistory } from "reducers/collaburation";
import JSZip from "jszip";
import { useSelector } from "react-redux";
import { RootState } from "store";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { modalResource, ModalContent } from "utils/sharedStyled";

// Define the props interface
interface MyComponentProps {}

interface Option {
  option_text: string;
  url: string | null;
  buffer_zone_phase: number;
  is_completed: boolean | undefined;
  pk: number;
}

interface Munitions {
  resource_url: string | null;
  comment: string;
}
// Define the functional component
const Task: React.FC<MyComponentProps> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [totalOptions, setTotalOptions] = useState(0);
  const [completedOptions, setCompletedOptions] = useState(0);
  const [checkboxOptions, setCheckboxOptions] = useState<Option[]>([]);
  const [munitions, setMunitions] = useState<Munitions[]>([]);
  const [aiDisabled, setAiDisabled] = useState(false);
  const dispatch = useAppDispatch();

  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split("/");
  const contentType = parts[2];
  const assessmentId = parts[3];

  const objectChangeHistoryList = useSelector(
    (state: RootState) => state.collaboration.objectChangeHistoryList
  );

  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const { state } = location;

  const phaseName = state && state.task;
  const phaseCardEmail = state && state.email;
  const ownerPkId = state && state.ownerId;

  const id = parseInt(assessmentId);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/task-board");
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(getBufferZoneQuestionWithowner({ id, ownerPk: ownerPkId }))
      .unwrap()
      .then((response) => {
        setTotalOptions(response?.total_options);
        setCompletedOptions(response?.completed_options);
        setCheckboxOptions(response?.options);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  }, [dispatch, assessmentId, ownerPkId]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getBufferZoneQuestionMutions({ id }))
      .unwrap()
      .then((response) => {
        setMunitions(response);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  }, [dispatch, assessmentId]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      objectChangeHistory({
        content_type: contentType,
        id: id,
        ownerPk: ownerPkId,
      })
    )
      .unwrap()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [dispatch, contentType, id, ownerPkId]);

  const handleCheckboxChange = React.useCallback(
    async (pk: number) => {
      if (userInfo?.email !== phaseCardEmail) {
        swal({
          title: "Unauthorized Access",
          text: "Sorry, You can't make this change!",
          icon: "error",
        });
        return;
      }

      // Find the option with the matching pk
      const optionToUpdate = checkboxOptions.find((option) => option.pk === pk);

      if (!optionToUpdate) return; // Handle if pk not found

      // Toggle the completion status locally
      setCheckboxOptions((prevOptions) =>
        prevOptions.map((option) =>
          option.pk === pk
            ? { ...option, is_completed: !option.is_completed }
            : option
        )
      );

      // Prepare the updated options for the dispatch
      const updatedOptions = checkboxOptions.map((option) =>
        option.pk === pk
          ? { ...option, is_completed: !option.is_completed }
          : option
      );

      try {
        await dispatch(
          bufferZoneQuestionResponse({
            index: pk, // Use pk directly in the request
            is_completed: updatedOptions.find((option) => option.pk === pk)
              ?.is_completed,
            task: true,
          })
        )
          .unwrap()
          .then((response: any) => {
            if (!response.success) {
              swal({
                title: "Unauthorized Access",
                text: "You cannot perform action; you have only view access!",
                icon: "error",
              });

              // Revert checkbox state in case of an error
              setCheckboxOptions((prevOptions) =>
                prevOptions.map((option) =>
                  option.pk === pk
                    ? { ...option, is_completed: !option.is_completed }
                    : option
                )
              );
            } else {
              // Update the completed count
              setCompletedOptions((prevTotal) =>
                updatedOptions.find((option) => option.pk === pk)?.is_completed
                  ? prevTotal + 1
                  : prevTotal - 1
              );
            }
          })
          .catch((err: Error) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    },
    [checkboxOptions, dispatch, setCheckboxOptions, setCompletedOptions]
  );

  const downloadZipFiles = (contents: any[]) => {
    const zip = new JSZip();

    try {
      // Add each item to the zip file
      contents.forEach((item, index) => {
        zip.file(`item${index + 1}`, item.resource_url);
      });

      // Generate the zip file
      zip.generateAsync({ type: "blob" }).then((content) => {
        // Create a download link
        const downloadLink = document.createElement("a");
        const url = URL.createObjectURL(content);

        downloadLink.href = url;
        downloadLink.download = "bufferLInk.zip";

        // Append the link to the document and trigger the download
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Remove the link from the document
        document.body.removeChild(downloadLink);
      });
    } catch (error) {
      console.log("error");
    }
  };

  const handleAssistant = () => {
    setAiDisabled(true);
    swal({
      title: "Tactical Advisor",
      text: "We're sorry, this feature is temporarily unavailable!",
      icon: "warning",
    });
    setAiDisabled(false);
    // dispatch(createThreadId())
    //   .then(() => {
    //     // Handle success

    //     navigate("/assistant");
    //     setAiDisabled(false);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  return (
    <DashboardWrapper>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          <Box
            mt={5}
            sx={{ background: "#FED74F", width: "20px", cursor: "pointer" }}
          >
            <Box pt={0.4} pl={0.3}>
              <FaAngleDoubleLeft onClick={handleNavigate} />
            </Box>
          </Box>
          <Box>
            <CustomeButton
              title={"Submit"}
              borderRadius="10px"
              fontWeight="600"
              fontSize="19px"
              align="right"
              width="120px"
              onClick={handleNavigate}
              disabled={completedOptions === totalOptions ? false : true}
            />
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <Box>
                  {id == 1 && <HoverImageOne />}
                  {id == 2 && <HoverImageTwo />}
                  {id == 3 && <HoverImageThree />}
                  {id == 4 && <HoverImageFour />}
                  {id == 5 && <HoverImageFive />}
                  {id == 6 && <HoverImageSix />}
                </Box>
                <Box>
                  <BufferProgress
                    assessmentName=""
                    assessmentIcons=""
                    value={(completedOptions / totalOptions) * 100}
                    completedQuestion={completedOptions}
                    totalQuestion={totalOptions}
                    color={
                      id === 1
                        ? Colors.text.navy
                        : id === 2
                        ? Colors.text.sky
                        : id === 3
                        ? Colors.text.orange
                        : id === 4
                        ? Colors.text.green
                        : id === 5
                        ? Colors.text.sky_1
                        : id === 6
                        ? Colors.text.theme
                        : Colors.text.black
                    }
                    align="left"
                    buffer={true}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              {id === 1 && <ReconnaissanceActive />}
              {id === 2 && <IntelligenceActive />}
              {id === 3 && <FortressActive />}
              {id === 4 && <TacticalActive />}
              {id === 5 && <WarActive />}
              {id === 6 && <BattleActive />}
            </Box>
            <Box ml={2}>
              <Text
                fontSize="18px"
                fontWeight="600"
                color={
                  id === 1
                    ? Colors.text.navy
                    : id === 2
                    ? Colors.text.sky
                    : id === 3
                    ? Colors.text.orange
                    : id === 4
                    ? Colors.text.green
                    : id === 5
                    ? Colors.text.sky_1
                    : id === 6
                    ? Colors.text.theme
                    : Colors.text.black
                }
              >
                {phaseName}
              </Text>
            </Box>
          </Box>
          <Box>
            <Text fontSize="24px" fontWeight="500">
              {id === 1
                ? "Complete each activity before moving to Intelligence Gathering."
                : id === 2
                ? "Complete each activity before moving to Fortress Fortification."
                : id === 3
                ? "Complete each activity before moving to Tactical Communication."
                : id === 4
                ? "Complete each activity before moving to War Games."
                : id === 5
                ? "Complete each activity before moving to Battle Ready."
                : id === 6
                ? "Complete each activity before selecting Mission Accomplished."
                : "Order and activities that needs to be completed before moving to the next zone."}
            </Text>
          </Box>
          {/* check box */}
          <Box>
            {checkboxOptions?.map((option) => (
              <Box key={option.pk}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={option.is_completed}
                      onChange={() => handleCheckboxChange(option.pk)}
                      style={{
                        color: option.is_completed
                          ? Colors.button.primary_bg
                          : Colors.button.primary_bg,
                      }}
                    />
                  }
                  label={option.option_text}
                />
              </Box>
            ))}
          </Box>
          <Box mt={4}>
            <Box mt={4}>
              <CustomeButton
                title={"Tactical Advisor"}
                borderRadius="50px"
                fontWeight="500"
                fontSize="17px"
                align="right"
                width="240px"
                background="#000"
                color="#fff"
                Icon={<GetAssessmentIcon />}
                onClick={handleAssistant}
                disabled={aiDisabled}
              />
            </Box>
          </Box>
          <Box mt={2}>
            <CustomeButton
              title={"Arsenal "}
              borderRadius="10px"
              fontWeight="500"
              fontSize="17px"
              align="left"
              width="140px"
              onClick={handleOpen}
            />
          </Box>
          <Box mt={4}>
            <Box>
              <Text
                fontSize="20px"
                fontWeight="600"
                color={Colors.text.sky}
                textDecoration="underline"
              >
                Summary
              </Text>
            </Box>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {objectChangeHistoryList?.map((item: any) => (
                <Box key={item.id}>
                  <ListItem alignItems="flex-start" sx={{ paddingLeft: "0px" }}>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{
                              display: "inline",
                              textTransform: "capitalize",
                            }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {item?.object_repr}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider />
                </Box>
              ))}
            </List>
          </Box>

          {/* modal */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <ModalContent sx={modalResource}>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Box>
                    {id === 1 && <ReconnaissanceActive />}
                    {id === 2 && <IntelligenceActive />}
                    {id === 3 && <FortressActive />}
                    {id === 4 && <TacticalActive />}
                    {id === 5 && <WarActive />}
                    {id === 6 && <BattleActive />}
                  </Box>
                  <Box ml={2}>
                    <Text
                      fontSize="18px"
                      fontWeight="600"
                      color={
                        id === 1
                          ? Colors.text.navy
                          : id === 2
                          ? Colors.text.sky
                          : id === 3
                          ? Colors.text.orange
                          : id === 4
                          ? Colors.text.green
                          : id === 5
                          ? Colors.text.sky_1
                          : id === 6
                          ? Colors.text.theme
                          : Colors.text.black
                      }
                    >
                      {phaseName}
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant="text"
                    sx={{
                      textDecoration: "underline",
                      textTransform: "capitalize",
                    }}
                    disabled={munitions.length > 0 ? false : true}
                    onClick={() => {
                      downloadZipFiles(munitions);
                    }}
                  >
                    Download All
                  </Button>
                </Box>
              </Box>
              <Box mt={2}>
                <AssetTable rows={munitions} />
              </Box>
            </ModalContent>
          </Modal>
        </>
      )}
    </DashboardWrapper>
  );
};

export default Task;
