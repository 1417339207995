import React, { useState, useEffect, useCallback } from "react";
import {
  DashboardWrapper,
  BufferProgress,
  Text,
  Stepper,
  Button,
  Loader,
  Collaboration,
} from "shared";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Box, Grid } from "@mui/material";
import HoverImageOne from "assets/one-img.svg";
import HoverImageTwo from "assets/two-img.svg";
import HoverImageThree from "assets/three-img.svg";
import HoverImageFour from "assets/four-img.svg";
import HoverImageFive from "assets/five-img.svg";
import HoverImageSix from "assets/six-img.svg";
import { ReactComponent as GetAssessmentIcon } from "assets/a-tag.svg";
import {
  Reconnaissance,
  Intelligence,
  Fortress,
  Tactical,
  War,
  Battle,
} from "utils/icons";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "hooks/useAppDispatch";
import { getMailBufferList } from "reducers/assessment";
import {
  assignPermission,
  fetchPermissionUserList,
  changeUserPermission,
  fetchAcceptedInvitedUsers,
  deleteUserPermission,
} from "reducers/collaburation";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Colors } from "theme/color";
import swal from "sweetalert";
import {
  phaseMapping,
  contentText,
  contentTextCompleted,
  FortressPackage,
} from "utils/constant";
import { LightTooltip } from "utils/sharedStyled";

interface CustomCardsProps {
  buffericon: {
    [key: string]: any;
    phase_name: string;
    icon_name: any;
    total_options: number;
    completed_options: number;
  };
  index: number;
}

interface IconComponentMap {
  [key: string]: React.ReactNode;
}

function getIconUrl(iconComponent: React.ReactNode): any {
  return (iconComponent as React.ReactElement)?.type || "";
}

const iconComponentMap: IconComponentMap = {
  "reconnaissance.svg": <HoverImageOne />,
  "intelligenceGathering.svg": <HoverImageTwo />,
  "fortressFortification.svg": <HoverImageThree />,
  "tacticalCommunication.svg": <HoverImageFour />,
  "warGames.svg": <HoverImageFive />,
  "battleReady.svg": <HoverImageSix />,
};

const CustomCards = styled(Box)<CustomCardsProps>`
  position: relative;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 5px rgba(254, 215, 79, 0.1);
  border: 0.4px solid #f4cf70;
  padding: 20px;
  overflow: hidden;

  &:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    line-height: 27px;
    transition: opacity 0.3s;
    opacity: 1;
    text-align: center;
  }

  &:hover::before {
    content: url(${(props) =>
        getIconUrl(iconComponentMap[props.buffericon.icon_name])})
      " ${(props) =>
        props.buffericon.completed_options === props.buffericon.total_options
          ? contentTextCompleted
          : contentText}";
    white-space: pre-line;
  }
`;

const CustomCard = styled(Box)({
  position: "relative",
  background: "#fff",
  borderRadius: "20px",
  boxShadow: "0px 0px 2px 5px rgba(254, 215, 79, 0.10)",
  border: "0.4px solid #f4cf70",
  padding: "20px",
  overflow: "hidden",
});

// Define the props interface
interface MyComponentProps {}

// Define the functional component
const MyComponent: React.FC<MyComponentProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [aiDisabled, setAiDisabled] = useState(false);

  const bufferList = useSelector(
    (state: RootState) => state.assessment?.bufferListData
  );

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  useEffect(() => {
    setIsLoading(true);
    dispatch(getMailBufferList())
      .unwrap()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [dispatch]);

  let activeSteperIndex = 5;

  for (let i = 0; i < bufferList?.length; i++) {
    const { total_options, completed_options } = bufferList[i];

    switch (true) {
      case total_options !== completed_options:
        activeSteperIndex = i;
        break;
    }
    if (activeSteperIndex !== 5) {
      break;
    }
  }

  const handleNavigate = () => {
    switch (activeSteperIndex) {
      case 0:
        navigate("/reconnaissance");
        break;
      case 1:
        navigate("/intelligence");
        break;
      case 2:
        navigate("/fortress");
        break;
      case 3:
        navigate("/tactical");
        break;
      case 4:
        navigate("/battle-ready");
        break;
      case 5:
        navigate("/reconnaissance");
        break;
      default:
      // Handle the case when activeSteperIndex doesn't match any of the specified cases
    }
  };

  const planRout = () => {
    navigate("/plan");
  };

  const handleAssistant = () => {
    setAiDisabled(true);
    swal({
      title: "Tactical Advisor",
      text: "We're sorry, this feature is temporarily unavailable!",
      icon: "warning",
    });
    setAiDisabled(false);
    // dispatch(createThreadId())
    //   .then(() => {
    //     // Handle success
    //     navigate("/assistant");
    //     setAiDisabled(false);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  // collaboration feature

  const [viewChecked, setViewChecked] = useState(false);
  const [writeChecked, setWriteChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [personName, setPersonName] = useState<string[]>([]);
  const [currentID, setCurrentID] = useState<number>();
  const [selectedUserID, setSelectedUserID] = useState<any[]>([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const [phaseName, setPhaseName] = useState<string[]>([]);
  const [battleOpen, setBattleOpen] = useState(false);
  const [phaseId, setPhaseId] = useState<number[]>([]);
  const [currentPhase, setCurrentPhase] = useState();

  const [userPermissions, setUserPermissions] = useState<any[]>([]);

  const [multiPhasesPermissions, setMultiPhasesPermissions] = useState<any[]>(
    []
  );

  const [multiPhaseFlag, setMultiPhaseFlag] = useState(false);

  const handleModalOpenBattle = () => {
    setWriteChecked(false);
    setViewChecked(false);
    setPhaseName([]);
    setPhaseId([]);
    setPersonName([]);
    setSelectedUserID([]);
    setMultiPhasesPermissions([]);
    setUserPermissions([]);
    setMultiPhaseFlag(true);
    setBattleOpen(true);
  };

  const handleModalCloseBattle = () => {
    setMultiPhaseFlag(false);
    setBattleOpen(false);
  };

  const handleModalOpen = (index: any, phase_name: any) => {
    setViewChecked(false);
    setWriteChecked(false);
    setOpen(true);
    setListLoader(true);
    const id = index;
    setCurrentID(id);
    setCurrentPhase(phase_name);
    dispatch(fetchPermissionUserList({ id: id, content_type: "buffer_zone" }))
      .unwrap()
      .then((response) => {
        setUserPermissions(response.response);
        setListLoader(false);
      })
      .catch((error: any) => {
        console.log(error);
        setListLoader(false);
        swal({
          title: "Assign Task",
          text: "Something went wrong please try again!",
          icon: "error",
        });
      });

    setSelectedUserID([]);
    setPersonName([]);
  };

  const handleViewChange = () => {
    setViewChecked(true);
    setWriteChecked(false);
  };

  const handleWriteChange = () => {
    setWriteChecked(true);
    setViewChecked(false);
  };

  const handleClose = () => {
    setOpen(false);
    setPhaseName([]);
    setPhaseId([]);
    setPersonName([]);
    setSelectedUserID([]);
    setMultiPhasesPermissions([]);
    setUserPermissions([]);
    setViewChecked(false);
    setWriteChecked(false);
  };

  const inviteUserList = useSelector(
    (state: RootState) => state.collaboration.inviteAcceptUserList
  );

  const inviteUser = inviteUserList?.map((item: any) => {
    const evidence = {
      id: item.pk,
      accepter: item.accepter,
      userName: item.email_address,
    };
    return evidence;
  });

  const handleAutocompleteChange = (
    event: any,
    selectedUsernames: string[]
  ) => {
    // Map selected usernames to their corresponding IDs
    const selectedUserIds = selectedUsernames?.map((username) => {
      const user = inviteUser.find((item: any) => item.userName === username);
      return user ? user.accepter : null;
    });
    // Filter out null values (IDs not found for some usernames)
    const validUserIds = selectedUserIds.filter((id) => id !== null);

    setSelectedUserID(validUserIds);
    // Update the state with selected names
    setPersonName(selectedUsernames);
  };

  function getUserEmail(accepter_id: any) {
    const user = userPermissions.find(
      (user) => user.accepter_id === accepter_id
    );
    return user ? user.user_email : null;
  }

  const handleAssignPermission = () => {
    if (selectedUserID.length === 0) {
      swal({
        title: "Assign Task",
        text: "Please Select user!",
        icon: "warning",
      });
      return;
    }
    if (!viewChecked && !writeChecked) {
      swal({
        title: "Assign Task",
        text: "Please Select Permission!",
        icon: "warning",
      });
      return;
    }
    setSubmitLoader(true);
    // Check if any accepter_id in selectedUserID exists in userPermissions
    const alreadyAssignedUsers = selectedUserID.filter((id) =>
      getUserEmail(id)
    );
    if (alreadyAssignedUsers.length > 0) {
      const assignedEmails = alreadyAssignedUsers
        .map((id) => getUserEmail(id))
        .join(", ");
      swal({
        title: "Assign Task",
        text: `The following users are already assigned permissions: ${assignedEmails}`,
        icon: "warning",
      });
    }

    const remainingUsers = selectedUserID.filter((id) => !getUserEmail(id));

    if (remainingUsers.length > 0) {
      dispatch(
        assignPermission({
          invited_user_ids: remainingUsers,
          object_id: [currentID],
          content_type: "buffer_zone",
          read_permission: viewChecked,
          write_permission: writeChecked,
        })
      )
        .unwrap()
        .then(() => {
          setSubmitLoader(false);
          setOpen(false);
          swal({
            title: "Assign Task",
            text: "Successfully Assign Task !",
            icon: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          setSubmitLoader(false);
          setOpen(false);
          swal({
            title: "Assign Task",
            text: "Something went wrong please try again",
            icon: "error",
          });
        });
    } else {
      setSubmitLoader(false);
      setOpen(false);
    }
  };

  const handleClickToggle = (email: string, buttonType: string) => {
    setUserPermissions((prevPermissions: any[]) => {
      const updatedPermissions = [...prevPermissions];
      const userIndex = updatedPermissions.findIndex(
        (user) => user.user_email === email
      );

      if (userIndex !== -1) {
        const updatedUser = { ...updatedPermissions[userIndex] };

        if (buttonType === "Viewer") {
          updatedUser.can_view = true;
          updatedUser.can_write = false;
        } else if (buttonType === "Editor") {
          updatedUser.can_view = false;
          updatedUser.can_write = true;
        }

        updatedPermissions[userIndex] = updatedUser;

        dispatch(
          changeUserPermission({
            invited_user_id: [updatedUser.accepter_id],
            object_id: [currentID],
            perm_codename: "buffer_zone",
            read_permission: updatedUser.can_view,
            write_permission: updatedUser.can_write,
          })
        )
          .unwrap()
          .then(() => {
            swal({
              title: "Change Permission",
              text: "Successfully Change Permission",
              icon: "success",
            });
          })
          .catch((err: any) => {
            console.log(err);
            swal({
              title: "Change Permission",
              text: "Something went wrong",
              icon: "error",
            });
          });
      }

      return updatedPermissions;
    });
  };

  const handleDeletePermission = (email: any) => {
    dispatch(
      deleteUserPermission({
        userId: [email.accepter_id],
        content_type: "buffer_zone",
        object_id: [currentID],
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          fetchPermissionUserList({
            id: currentID,
            content_type: "buffer_zone",
          })
        )
          .unwrap()
          .then((response) => {
            setUserPermissions(response.response);
            setListLoader(false);
          })
          .catch((error: any) => {
            console.log(error);
            setListLoader(false);
          });
        swal({
          title: "Remove Permission",
          text: "Successfully Remove Permission",
          icon: "success",
        });
      })
      .catch((err: any) => {
        console.log(err);
        swal({
          title: "Remove Permission",
          text: "Something went wrong",
          icon: "error",
        });
      });
  };

  const fetchPermissions = async (currentPhaseId: number) => {
    try {
      const response = await dispatch(
        fetchPermissionUserList({
          id: currentPhaseId,
          content_type: "buffer_zone",
        })
      ).unwrap();
      setMultiPhasesPermissions((prevUserPermissions) => [
        ...prevUserPermissions,
        ...response.response,
      ]);

      setListLoader(false);
    } catch (error) {
      console.log(error);
      setListLoader(false);
      setMultiPhasesPermissions([]);
      swal({
        title: "Assign Task",
        text: "Something went wrong, please try again!",
        icon: "error",
      });
    }
  };

  const handleBufferPhaseSelect = useCallback(
    (event: any, name: any) => {
      const ids = name?.map((item: string) => phaseMapping[item]);
      const id = name?.map((item: string) => phaseMapping[item]);
      const lastId = id?.pop();
      if (lastId !== undefined && name.length > phaseName.length) {
        fetchPermissions(lastId);
      } else if (lastId === undefined) {
        setMultiPhasesPermissions([]);
      }

      setPhaseName(name);
      setPhaseId(ids);
    },
    [multiPhasesPermissions, phaseId, phaseName]
  );

  const handleAssignMultiplePermission = () => {
    if (phaseId.length === 0) {
      swal({
        title: "Assign Task",
        text: "Please Select Buffer Zone Phase!",
        icon: "warning",
      });
      return;
    }
    if (selectedUserID.length === 0) {
      swal({
        title: "Assign Task",
        text: "Please Select User!",
        icon: "warning",
      });
      return;
    }

    if (!viewChecked && !writeChecked) {
      swal({
        title: "Assign Task",
        text: "Please Select Permission!",
        icon: "warning",
      });
      return;
    }
    setSubmitLoader(true);

    dispatch(
      assignPermission({
        invited_user_ids: selectedUserID,
        object_id: phaseId,
        content_type: "buffer_zone",
        read_permission: viewChecked,
        write_permission: writeChecked,
      })
    )
      .unwrap()
      .then(() => {
        setSubmitLoader(false);
        setBattleOpen(false);
        swal({
          title: "Assign Task",
          text: "Successfully Assign Task !",
          icon: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        setSubmitLoader(false);
        setBattleOpen(false);
        swal({
          title: "Assign Task",
          text: "Something went wrong please try again",
          icon: "error",
        });
      });
  };

  const bufferPhasesName =
    bufferList?.map((item: any) => item.phase_name) || [];

  const [userSearch, setUserSearch] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [loader, setLoader] = useState(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchLoading === false) {
      const newSearchText = event.target.value;
      setUserSearch(newSearchText);
    }
  };

  useEffect(() => {
    setSearchLoading(true);
    dispatch(
      fetchAcceptedInvitedUsers({
        page: 1,
        limit: 15,
        search: userSearch,
      })
    )
      .unwrap()
      .then(() => {
        setLoader(false);
        setSearchLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setSearchLoading(false);
        setLoader(false);
      });
  }, [dispatch, userSearch]);

  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const ownerId = userInfo?.pk;
  const handleViewResouce = () => {
    const task = currentPhase;
    navigate(`/task/buffer_zone/${currentID}`, {
      state: { task, ownerId },
    });
  };

  return (
    <DashboardWrapper>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={4}>
            <CustomCard>
              <Box display="flex" justifyContent="space-between">
                <Text fontSize="18px" fontWeight="600" align="left">
                  Battle Plan
                </Text>
                <Box>
                  <Collaboration
                    title="Buffer Phases"
                    viewChecked={viewChecked}
                    writeChecked={writeChecked}
                    handleWriteChange={handleWriteChange}
                    handleViewChange={handleViewChange}
                    personName={personName}
                    open={battleOpen}
                    handleOpen={handleModalOpenBattle}
                    handleClose={handleModalCloseBattle}
                    handleChangeSelect={handleAutocompleteChange}
                    handleSubmit={handleAssignMultiplePermission}
                    loaderButton={submitLoader}
                    listLoader={listLoader}
                    handleChangeSelectBufferPhase={handleBufferPhaseSelect}
                    phaseName={phaseName}
                    showPhase={true}
                    bufferPhasesName={bufferPhasesName}
                    viewResource={false}
                    multipleUserPermissions={multiPhasesPermissions}
                    handleClickToggle={handleClickToggle}
                    handleDeletePermission={handleDeletePermission}
                    multiPhaseFlag={multiPhaseFlag}
                  />
                </Box>
              </Box>
              <Box mt={8} mb={6}>
                <Stepper activeStep={activeSteperIndex} />
              </Box>
            </CustomCard>
          </Grid>

          <Grid container spacing={2} mt={5}>
            {bufferList?.map((item: any, index: any) => (
              <Grid item xl={4} lg={4} md={6} sm={12} xs={12} key={index}>
                <Box>
                  <Box mb={1} display="flex" justifyContent="end">
                    <Collaboration
                      title="Buffer Phases"
                      viewChecked={viewChecked}
                      writeChecked={writeChecked}
                      handleWriteChange={handleWriteChange}
                      handleViewChange={handleViewChange}
                      personName={personName}
                      open={open}
                      handleOpen={() =>
                        handleModalOpen(item.pk, item?.phase_name)
                      }
                      handleClose={handleClose}
                      handleChangeSelect={handleAutocompleteChange}
                      handleSubmit={handleAssignPermission}
                      loaderButton={submitLoader}
                      listLoader={listLoader}
                      userPermissions={userPermissions}
                      handleClickToggle={handleClickToggle}
                      handleDeletePermission={handleDeletePermission}
                      handleInputChange={handleInputChange}
                      loader={loader}
                      handleViewResouce={handleViewResouce}
                    />
                  </Box>
                  <CustomCards buffericon={item} index={index}>
                    <BufferProgress
                      assessmentName={item.phase_name}
                      assessmentIcons={
                        item.icon_name === "reconnaissance.svg" ? (
                          <Reconnaissance />
                        ) : item.icon_name === "intelligenceGathering.svg" ? (
                          <Intelligence />
                        ) : item.icon_name === "fortressFortification.svg" ? (
                          <Fortress />
                        ) : item.icon_name === "tacticalCommunication.svg" ? (
                          <Tactical />
                        ) : item.icon_name === "warGames.svg" ? (
                          <War />
                        ) : item.icon_name === "battleReady.svg" ? (
                          <Battle />
                        ) : null
                      }
                      value={
                        (item.completed_options / item.total_options) * 100
                      }
                      completedQuestion={item.completed_options}
                      totalQuestion={item.total_options}
                      color={
                        item.icon_name === "reconnaissance.svg"
                          ? Colors.text.navy
                          : item.icon_name === "intelligenceGathering.svg"
                          ? Colors.text.sky
                          : item.icon_name === "fortressFortification.svg"
                          ? Colors.text.orange
                          : item.icon_name === "tacticalCommunication.svg"
                          ? Colors.text.green
                          : item.icon_name === "warGames.svg"
                          ? Colors.text.sky_1
                          : item.icon_name === "battleReady.svg"
                          ? Colors.text.theme
                          : null
                      }
                    />
                  </CustomCards>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box mt={4}>
            {currentPackage === FortressPackage ? (
              <Box mt={4}>
                <Button
                  title={"Tactical Advisor"}
                  borderRadius="50px"
                  fontWeight="500"
                  fontSize="17px"
                  align="right"
                  width="240px"
                  background="#000"
                  color="#fff"
                  Icon={<GetAssessmentIcon />}
                  onClick={handleAssistant}
                  disabled={aiDisabled}
                />
              </Box>
            ) : (
              <Box mt={4} display="flex" justifyContent="end">
                <LightTooltip
                  arrow
                  placement="top"
                  title="Subscribe Fortress (Price $197/M Billed Annually) To Unlock Tactical Advisor"
                >
                  <Box width="240px">
                    <Button
                      title={"Tactical Advisor"}
                      borderRadius="50px"
                      fontWeight="500"
                      fontSize="17px"
                      align="right"
                      width="240px"
                      background="#000"
                      color="#fff"
                      Icon={<GetAssessmentIcon />}
                      onClick={planRout}
                    />
                  </Box>
                </LightTooltip>
              </Box>
            )}
          </Box>

          <Box mt={2}>
            <Button
              title={"Continue Mission"}
              borderRadius="10px"
              fontWeight="500"
              fontSize="19px"
              align="left"
              width="220px"
              onClick={handleNavigate}
            />
          </Box>
        </>
      )}
    </DashboardWrapper>
  );
};

export default MyComponent;
