import React from "react";
import { ModalContent, style, Header, Lable } from "./style";
import { Modal, Box, MenuItem, Select } from "@mui/material";
import { Colors } from "theme/color";
import { Button, Text, Input } from "shared";
import { BiPlusCircle } from "react-icons/bi";
import { assessmentTypeNISTCSFQSG } from "utils/constant";

import { RxCrossCircled } from "react-icons/rx";
import { Formik } from "formik";
import * as Yup from "yup";

const AssesmentSchema = Yup.object().shape({
  assessment: Yup.string()
    .matches(
      /^[A-Za-z0-9 ]+$/,
      "Only alphabetic characters, numbers and spaces are allowed"
    )
    .required("Assessment Name is required")
    .min(1, "Too Short!")
    .max(255, "Too Long!"),
});

interface MyComponentProps {
  isLoading: any;
  isDisabled: any;
  open: any;
  handleClose: () => void;
  handleAssessment: // eslint-disable-next-line no-unused-vars
  (values: { assessment: string; companyName: string }) => Promise<void>;
  assessmentType: any;
  handleSelect: any;
  targeAssessmentLevel: any;
  assessmentTypeNISTCSF: any;
  handleSelectTargetLevel: any;
  assessmentLevel: any;
  handleSelectLevel: any;
}

const AddAssessmentModal: React.FC<MyComponentProps> = ({
  isLoading,
  isDisabled,
  open,
  handleClose,
  handleAssessment,
  assessmentType,
  handleSelect,
  targeAssessmentLevel,
  assessmentTypeNISTCSF,
  handleSelectTargetLevel,
  assessmentLevel,
  handleSelectLevel,
}) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent sx={style}>
          <Header
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text fontSize="25px" fontWeight="700" color={Colors.text.white}>
              Add Assessment
            </Text>
            <RxCrossCircled
              onClick={handleClose}
              style={{ cursor: "pointer" }}
              color={Colors.text.white}
              fontSize="25px"
            />
          </Header>

          <Box m={4}>
            <Box>
              <Formik
                initialValues={{
                  assessment: "",
                  companyName: "",
                }}
                validationSchema={AssesmentSchema}
                onSubmit={handleAssessment}
              >
                {({ errors, touched, values, handleChange, handleSubmit }) => (
                  <Box>
                    <Box>
                      <Box p={{ sx: 1, md: 1, lg: 1 }}>
                        <Box>
                          <Input
                            name="assessment"
                            type="text"
                            label="Assessment Name"
                            value={values.assessment}
                            onChange={handleChange("assessment")}
                          />
                          {errors.assessment && touched.assessment ? (
                            <div className="error-message">
                              {errors.assessment}
                            </div>
                          ) : null}
                          <Input
                            name="companyName"
                            type="text"
                            label="Company Name"
                            value={values.companyName}
                            onChange={handleChange("companyName")}
                          />
                          {errors.companyName && touched.companyName ? (
                            <div className="error-message">
                              {errors.companyName}
                            </div>
                          ) : null}
                          <Box>
                            <Lable>Assessment Type </Lable>
                            <Select
                              labelId="demo-select-small"
                              name="assessmentType"
                              id="demo-select-small"
                              value={assessmentType}
                              placeholder="...Select..."
                              onChange={handleSelect}
                              sx={{
                                "&.MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "transparent !important",
                                    height: "20px  !important",
                                    // Hide the root border
                                  },
                                  "&:hover fieldset, &.Mui-focused fieldset": {
                                    borderColor: "transparent !important", // Hide the border on hover and focus
                                  },
                                },
                                "& .MuiSelect-icon": {
                                  color: "#FED74F",
                                  border: "1px solid #FED74F",
                                  borderRadius: "50px",
                                },
                                "& .MuiSelect-select": {
                                  borderColor: "#FED74F !important",
                                  border: "2px solid #FED74F !important",
                                  borderRadius: "50px !important",
                                  background: "#fff",
                                  padding: "20px 20px !important",
                                  "&:hover, &:focus": {
                                    borderColor: "#FED74F !important",
                                    border: "2px solid #FED74F !important",
                                  },
                                },
                                width: { xs: "100%", md: "100%" },
                              }}
                            >
                              <MenuItem
                                value="NIST SP 800-171"
                                sx={{
                                  "&.Mui-selected": {
                                    backgroundColor: Colors.button.primary_bg,
                                  },
                                }}
                              >
                                NIST SP 800-171
                              </MenuItem>
                              <MenuItem
                                value="NIST CSF 2.0"
                                sx={{
                                  "&.Mui-selected": {
                                    backgroundColor: Colors.button.primary_bg,
                                  },
                                }}
                              >
                                NIST CSF 2.0
                              </MenuItem>
                              <MenuItem
                                value="NIST CSF Quick Start Guide"
                                sx={{
                                  "&.Mui-selected": {
                                    backgroundColor: Colors.button.primary_bg,
                                  },
                                }}
                              >
                                NIST CSF Quick Start Guide
                              </MenuItem>
                            </Select>
                          </Box>

                          {assessmentType === assessmentTypeNISTCSFQSG ||
                          assessmentType === assessmentTypeNISTCSF ? (
                            <Box>
                              <Lable>Target Tier</Lable>
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={targeAssessmentLevel}
                                onChange={handleSelectTargetLevel}
                                sx={{
                                  "&.MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "transparent !important",
                                      height: "20px  !important",
                                      // Hide the root border
                                    },
                                    "&:hover fieldset, &.Mui-focused fieldset":
                                      {
                                        borderColor: "transparent !important", // Hide the border on hover and focus
                                      },
                                  },
                                  "& .MuiSelect-icon": {
                                    color: "#FED74F",
                                    border: "1px solid #FED74F",
                                    borderRadius: "50px",
                                  },
                                  "& .MuiSelect-select": {
                                    borderColor: "#FED74F !important",
                                    border: "2px solid #FED74F !important",
                                    borderRadius: "50px !important",
                                    background: "#fff",
                                    padding: "20px 20px !important",
                                    "&:hover, &:focus": {
                                      borderColor: "#FED74F !important",
                                      border: "2px solid #FED74F !important",
                                    },
                                  },
                                  width: { xs: "100%", md: "100%" },
                                }}
                              >
                                <MenuItem
                                  value="Partial"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  Tier 1: Partial
                                </MenuItem>
                                <MenuItem
                                  value="Risk-Informed"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  Tier 2: Rsik-Informed
                                </MenuItem>
                                <MenuItem
                                  value="Repeatable"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  Tier 3: Repeatable
                                </MenuItem>
                                <MenuItem
                                  value="Adaptive"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  Tier 4: Adaptive
                                </MenuItem>
                              </Select>
                            </Box>
                          ) : (
                            <Box>
                              <Lable>Assessment Levels </Lable>
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={assessmentLevel}
                                onChange={handleSelectLevel}
                                sx={{
                                  "&.MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "transparent !important",
                                      height: "20px  !important",
                                      // Hide the root border
                                    },
                                    "&:hover fieldset, &.Mui-focused fieldset":
                                      {
                                        borderColor: "transparent !important", // Hide the border on hover and focus
                                      },
                                  },
                                  "& .MuiSelect-icon": {
                                    color: "#FED74F",
                                    border: "1px solid #FED74F",
                                    borderRadius: "50px",
                                  },
                                  "& .MuiSelect-select": {
                                    borderColor: "#FED74F !important",
                                    border: "2px solid #FED74F !important",
                                    borderRadius: "50px !important",
                                    background: "#fff",
                                    padding: "20px 20px !important",
                                    "&:hover, &:focus": {
                                      borderColor: "#FED74F !important",
                                      border: "2px solid #FED74F !important",
                                    },
                                  },
                                  width: { xs: "100%", md: "100%" },
                                }}
                              >
                                <MenuItem
                                  value="1"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  CMMC Level 1
                                </MenuItem>
                                <MenuItem
                                  value="2"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  CMMC Level 2
                                </MenuItem>
                              </Select>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box pt={3}>
                      <Button
                        title={isLoading ? "Loading..." : "Add Assessment"}
                        borderRadius="50px"
                        fontWeight="500"
                        fontSize="18px"
                        align="center"
                        Icon={<BiPlusCircle />}
                        onClick={handleSubmit}
                        width="230px"
                        disabled={isDisabled}
                      />
                    </Box>
                  </Box>
                )}
              </Formik>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddAssessmentModal;
