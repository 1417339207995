import React, { useState, useEffect } from "react";
import {
  DashboardWrapper,
  CustomeButton,
  BufferProgress,
  Text,
  AssetTable,
  Loader,
} from "shared";
import { Box, Grid, Button, Modal } from "@mui/material";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { ReactComponent as HoverImageOne } from "assets/four-img.svg";
import { Colors } from "theme/color";
import Checkbox from "@mui/material/Checkbox";

import { ReactComponent as GetAssessmentIcon } from "assets/a-tag.svg";
import { TacticalActive } from "utils/icons";
import { useNavigate, useLocation } from "react-router-dom";
import useAppDispatch from "hooks/useAppDispatch";
import {
  getBufferZoneQuestion,
  bufferZoneQuestionResponse,
  getBufferZoneQuestionMutions,
} from "reducers/assessment";
import JSZip from "jszip";

import { useSelector } from "react-redux";
import { RootState } from "store";
import { LightTooltip, modalResource, ModalContent } from "utils/sharedStyled";
import { FortressPackage } from "utils/constant";
import swal from "sweetalert";

// Define the props interface
interface MyComponentProps {}
interface Option {
  option_text: string;
  url: string | null;
  buffer_zone_phase: number;
  is_completed: boolean;
  pk: number;
}

interface Munitions {
  resource_url: string | null;
  comment: string;
}

// Define the functional component
const MyComponent: React.FC<MyComponentProps> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [totalOptions, setTotalOptions] = useState(0);
  const [completedOptions, setCompletedOptions] = useState(0);
  const [checkboxOptions, setCheckboxOptions] = useState<Option[]>([]);
  const [munitions, setMunitions] = useState<Munitions[]>([]);
  const dispatch = useAppDispatch();
  const [aiDisabled, setAiDisabled] = useState(false);

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split("/");
  const assessmentId = parts[1];

  const id = assessmentId === "tactical" ? 4 : 0;
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const navigate = useNavigate();
  const handlePrevious = () => {
    navigate("/fortress");
  };
  const handleNavigate = () => {
    navigate("/battle-ready");
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(getBufferZoneQuestion({ id }))
      .unwrap()
      .then((response) => {
        setTotalOptions(response?.total_options);
        setCompletedOptions(response?.completed_options);
        setCheckboxOptions(response?.options);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  }, [dispatch, assessmentId]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getBufferZoneQuestionMutions({ id }))
      .unwrap()
      .then((response) => {
        setMunitions(response);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  }, [dispatch, assessmentId]);

  const handleCheckboxChange = (pk: number) => {
    setCheckboxOptions((prevOptions) => {
      // Map by `pk` to find and update the correct option
      const updatedOptions = prevOptions.map((option) =>
        option.pk === pk
          ? { ...option, is_completed: !option.is_completed }
          : option
      );

      // Return the updated options
      return updatedOptions;
    });

    // Find the specific option being updated by `pk`
    const updatedOption = checkboxOptions.find((option) => option.pk === pk);

    // Toggle the completion state of the specific option
    if (updatedOption) {
      setCompletedOptions((prevTotal) =>
        updatedOption.is_completed ? prevTotal - 1 : prevTotal + 1
      );
    }

    // Dispatch action with the updated is_completed value and `pk`
    dispatch(
      bufferZoneQuestionResponse({
        index: pk,
        is_completed: !updatedOption?.is_completed, // Toggle the state
        task: false,
      })
    );
  };

  const downloadZipFiles = (contents: any[]) => {
    const zip = new JSZip();

    try {
      // Add each item to the zip file
      contents.forEach((item, index) => {
        zip.file(`item${index + 1}`, item.resource_url);
      });

      // Generate the zip file
      zip.generateAsync({ type: "blob" }).then((content) => {
        // Create a download link
        const downloadLink = document.createElement("a");
        const url = URL.createObjectURL(content);

        downloadLink.href = url;
        downloadLink.download = "bufferLInk.zip";

        // Append the link to the document and trigger the download
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Remove the link from the document
        document.body.removeChild(downloadLink);
      });
    } catch (error) {
      console.log("error");
    }
  };

  const handleAssistant = () => {
    setAiDisabled(true);
    swal({
      title: "Tactical Advisor",
      text: "We're sorry, this feature is temporarily unavailable!",
      icon: "warning",
    });
    setAiDisabled(false);
    // dispatch(createThreadId())
    //   .then(() => {
    //     // Handle success

    //     navigate("/assistant");
    //     setAiDisabled(false);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const planRout = () => {
    navigate("/plan");
  };

  return (
    <DashboardWrapper>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          <Box
            mt={5}
            sx={{ background: "#FED74F", width: "20px", cursor: "pointer" }}
          >
            <Box pt={0.4} pl={0.3}>
              <FaAngleDoubleLeft onClick={handlePrevious} />
            </Box>
          </Box>
          <Box mt={1}>
            <CustomeButton
              title={"Next"}
              borderRadius="10px"
              fontWeight="600"
              fontSize="19px"
              align="right"
              width="120px"
              onClick={handleNavigate}
              disabled={completedOptions === totalOptions ? false : true}
            />
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <Box>
                  <HoverImageOne />
                </Box>
                <Box>
                  <BufferProgress
                    assessmentName=""
                    assessmentIcons=""
                    value={(completedOptions / totalOptions) * 100}
                    completedQuestion={completedOptions}
                    totalQuestion={totalOptions}
                    color="#0FD341"
                    align="left"
                    buffer={true}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex">
            <Box>
              <TacticalActive />
            </Box>
            <Box mt={0.6} ml={2}>
              <Text fontSize="18px" fontWeight="600" color={Colors.text.green}>
                Tactical Communication
              </Text>
            </Box>
          </Box>
          <Box>
            <Text fontSize="24px" fontWeight="500">
              Complete each activity before moving to Battle Ready.
            </Text>
          </Box>
          {/* check box */}
          <Box>
            {checkboxOptions?.map((option) => (
              <Box key={option.pk} display="flex" alignItems="center">
                <Checkbox
                  checked={option.is_completed}
                  onChange={() => handleCheckboxChange(option.pk)}
                  style={{
                    color: option.is_completed
                      ? Colors.button.primary_bg
                      : Colors.button.primary_bg,
                  }}
                />
                <Box ml={1}>{option.option_text}</Box>
              </Box>
            ))}
          </Box>
          <Box mt={4}>
            {currentPackage === FortressPackage ? (
              <Box mt={4}>
                <CustomeButton
                  title={"Tactical Advisor"}
                  borderRadius="50px"
                  fontWeight="500"
                  fontSize="17px"
                  align="right"
                  width="240px"
                  background="#000"
                  color="#fff"
                  Icon={<GetAssessmentIcon />}
                  onClick={handleAssistant}
                  disabled={aiDisabled}
                />
              </Box>
            ) : (
              <Box mt={4} display="flex" justifyContent="end">
                <LightTooltip
                  arrow
                  placement="top"
                  title="Subscribe Fortress (Price $197/M Billed Annually) To Unlock Tactical Advisor"
                >
                  <Box width="240px">
                    <CustomeButton
                      title={"Tactical Advisor"}
                      borderRadius="50px"
                      fontWeight="500"
                      fontSize="17px"
                      align="right"
                      width="240px"
                      background="#000"
                      color="#fff"
                      Icon={<GetAssessmentIcon />}
                      onClick={planRout}
                    />
                  </Box>
                </LightTooltip>
              </Box>
            )}
          </Box>
          <Box mt={2}>
            <CustomeButton
              title={"Arsenal "}
              borderRadius="10px"
              fontWeight="500"
              fontSize="17px"
              align="left"
              width="140px"
              onClick={handleOpen}
            />
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <ModalContent sx={modalResource}>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex">
                  <Box>
                    <TacticalActive />
                  </Box>
                  <Box mt={0.5} ml={2}>
                    <Text
                      fontSize="18px"
                      fontWeight="600"
                      color={Colors.text.green}
                    >
                      Tactical Communication
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant="text"
                    sx={{
                      textDecoration: "underline",
                      textTransform: "capitalize",
                    }}
                    disabled={munitions.length > 0 ? false : true}
                    onClick={() => {
                      downloadZipFiles(munitions);
                    }}
                  >
                    Download All
                  </Button>
                </Box>
              </Box>
              <Box mt={2}>
                <AssetTable rows={munitions} />
              </Box>
            </ModalContent>
          </Modal>
        </>
      )}
    </DashboardWrapper>
  );
};

export default MyComponent;
