import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const LogoBox = styled(Box)({
  width: "100px",
  height: "100px",
  marginTop: "5px",
});

export const CustomAvatar = styled("img")({
  width: "100%",
  height: "100%",
});

export const PackageType = styled("img")({
  width: "30px",
  height: "30px",
});

export const CustomTick = styled("img")({
  width: "30px",
  height: "30px",
});

export const Card = styled(Box)<CardProps>(({ backgroundColor }) => ({
  borderRadius: "20px",
  backgroundColor: backgroundColor || "#fff", // Default to #060606 if no backgroundColor prop is provided
}));
type CardProps = {
  backgroundColor?: string;
  borderColor?: string;
};

export const SubscriptionMessage = styled(Box)(() => ({
  padding: "10px",
  width: "fit-content",
  borderRadius: "5px",
  cursor: "pointer",
}));
