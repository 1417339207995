import React, { FunctionComponent, ReactNode, useCallback } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { Box, Stack, Modal, Button } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCrossCircled } from "react-icons/rx";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CgProfile } from "react-icons/cg";
import { AiOutlineLogout } from "react-icons/ai";
import { CgHomeAlt } from "react-icons/cg";
import { BsFileEarmarkText } from "react-icons/bs";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { TransparentLogo, Header, Text, LexicalEditor } from "shared";
import { Colors } from "theme/color";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { logoutAction, resetAuthToken } from "utils/auth";
import { userLogout } from "reducers/user";
import useAppDispatch from "hooks/useAppDispatch";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { COMMON } from "utils/common";
import AssetInventory from "assets/Union.png";

import { TbStack, TbFountain } from "react-icons/tb";

import DefaultImg from "assets/member-avatar-01.png";
import { MdOutlineLock, MdSpaceDashboard } from "react-icons/md";
import { LightTooltip } from "utils/sharedStyled";
import Standard from "assets/standard.png";
import Premium from "assets/premium.png";
import Basic from "assets/basic.png";
import { CgArrowsExchange } from "react-icons/cg";
import { MdPrivacyTip } from "react-icons/md";
import { FrontlineDefender, Bunker, FortressPackage } from "utils/constant";

import { TermAndCondition } from "pages";

const PackageAvatar = styled("img")({
  width: "25px",
  height: "25px",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  borderRadius: "50%",
});

export const ModalContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

export const HeaderModal = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: theme.spacing(2),
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
}));

interface SidebarItem {
  title?: any;
  icon?: ReactNode;
  link?: any;
}

const drawerWidth = 230;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.down("sm")]: {
    zIndex: theme.zIndex.drawer - 1,
  },
  [theme.breakpoints.up("md")]: {
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Avatar = styled("img")(({ theme }) => ({
  width: "46px",
  height: "46px",
  border: "3px solid #FED74F",
  borderRadius: "50px",
  boxShadow: "0px 0px 3.30849px 4.13561px #000000",
  marginTop: "-5px",
  [theme.breakpoints.down("sm")]: {
    width: "40px",
    height: "40px",
    border: "3px solid #FED74F",
    borderRadius: "50px",
    boxShadow: "0px 0px 3.30849px 4.13561px #000000",
    marginTop: "-1px",
  },
}));

type Props = {
  children?: ReactNode | ReactNode[];
};

const DashboardWrapper: FunctionComponent<Props> = ({ children }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);

  const [openModal, setOpenModal] = React.useState(false);
  const [featureModal, setFeatureModal] = React.useState(false);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleCloseFeature = () => {
    setFeatureModal(false);
  };

  const openFeatureModal = () => {
    setFeatureModal(true);
  };

  const handleModalClick = () => {
    setIsScrolledToBottom(false);
    setOpenModal(true);
  };

  const userImge = useSelector(
    (state: RootState) => state.user.userInfo?.users_profile?.image
  );

  const baseURL = COMMON.apiBaseUrl;
  const trimmedImagePath = userImge?.substring(1);

  const profileImage = `${baseURL}${trimmedImagePath}`;

  const defaultImg = userImge === undefined ? DefaultImg : profileImage;

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleLogout = useCallback(async () => {
    dispatch(userLogout())
      .unwrap()
      .then(() => {
        setTimeout(() => {
          logoutAction();
          resetAuthToken();
        }, 5000); // wait for 5 seconds before executing logoutAction()
        navigate("/signin");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }, [dispatch]);

  const handleItemClick = (link: any) => {
    if (link === "/signin") {
      handleLogout();
    }
    navigate(link);
    setAnchorElUser(null);
  };
  const location = useLocation();
  const [activeItem, setActiveItem] = React.useState<SidebarItem | null>(null);

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );
  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  const settings = [
    { text: "Profile", icon: <CgProfile color="#000" />, link: "/profile" },
    {
      text: "Change Plan",
      icon: <CgArrowsExchange color="#000" />,
      link: "/plan",
    },
    { text: "Logout", icon: <AiOutlineLogout color="#000" />, link: "/signin" },
  ];

  const Sidebar: SidebarItem[] = [
    currentPackage === FortressPackage
      ? {
          title: <Box display="flex">Buffer Zones </Box>,
          icon: <TbStack color="#000" fontWeight="700" fontSize="24px" />,
          link: "/buffer-zone",
        }
      : {
          title: (
            <LightTooltip
              arrow
              placement="top"
              title="Subscribe Fortress (Price $197/M Billed Annually) To Unlock Buffer Zone"
            >
              <Box display="flex">
                Buffer Zone{" "}
                <MdOutlineLock
                  style={{ fontSize: "20px", marginLeft: "5px" }}
                />
              </Box>
            </LightTooltip>
          ),
          icon: <TbStack color="#000" fontWeight="700" fontSize="24px" />,
          link: "/plan",
        },

    currentPackage !== "Null"
      ? {
          title: "Mission Control",
          icon: <CgHomeAlt color="#000" fontWeight="700" fontSize="24px" />,
          link: "/dashboard",
        }
      : {
          title: (
            <LightTooltip
              arrow
              placement="top"
              title="Subscribe Frontline Defender (FREE) To Unlock Dashboard"
            >
              <Box display="flex">
                Dashboard{" "}
                <MdOutlineLock
                  style={{ fontSize: "20px", marginLeft: "5px" }}
                />
              </Box>
            </LightTooltip>
          ),
          icon: <CgHomeAlt color="#000" fontWeight="700" fontSize="22px" />,
          link: "/plan",
        },

    currentPackage !== "Null"
      ? {
          title: "Assessment",
          icon: (
            <BsFileEarmarkText color="#000" fontWeight="700" fontSize="24px" />
          ),
          link:
            currentPackage === FrontlineDefender
              ? "/assessment/nist-csf"
              : "/assessment/nist-sp-800-171/level/1",
        }
      : {
          title: (
            <LightTooltip
              arrow
              placement="top"
              title="Subscribe Frontline Defender (FREE) To Unlock Nist CSF 2.0 Quick Start Guide Assessment"
            >
              <Box display="flex">
                Assessment{" "}
                <MdOutlineLock
                  style={{ fontSize: "20px", marginLeft: "5px" }}
                />
              </Box>
            </LightTooltip>
          ),
          icon: (
            <BsFileEarmarkText color="#000" fontWeight="700" fontSize="22px" />
          ),
          link: "/plan",
        },

    currentPackage === Bunker || currentPackage === FortressPackage
      ? {
          title: <Box display="flex">Evidence </Box>,
          icon: (
            <VscWorkspaceTrusted
              color="#000"
              fontWeight="700"
              fontSize="22px"
            />
          ),
          link: "/evidence",
        }
      : {
          title: (
            <LightTooltip
              arrow
              placement="top"
              title="Subscribe Bunker $499 Per Year To Unlock Evidence Vault"
            >
              <Box display="flex">
                Evidence{" "}
                <MdOutlineLock
                  style={{ fontSize: "20px", marginLeft: "5px" }}
                />
              </Box>
            </LightTooltip>
          ),
          icon: (
            <VscWorkspaceTrusted
              color="#000"
              fontWeight="700"
              fontSize="22px"
            />
          ),
          link: "/plan",
        },

    currentPackage !== "Null"
      ? {
          title: "Asset Inventory",
          icon: (
            <img
              src={AssetInventory}
              alt="Inventory"
              style={{
                color: "#000",
                fontWeight: "700",
                fontSize: "20px",
                width: "20px",
                height: "20px",
              }}
            />
          ),
          link: "/inventory",
        }
      : {
          title: (
            <LightTooltip
              arrow
              placement="top"
              title="Subscribe Frontline Defender (FREE) To Unlock Asset Inventory"
            >
              <Box display="flex">
                Assets Inventory{" "}
                <MdOutlineLock
                  style={{ fontSize: "20px", marginLeft: "5px" }}
                />
              </Box>
            </LightTooltip>
          ),
          icon: (
            <img
              src={AssetInventory}
              alt="Inventory"
              style={{
                color: "#000",
                fontWeight: "700",
                fontSize: "20px",
                width: "20px",
                height: "20px",
              }}
            />
          ),
          link: "/plan",
        },
    {
      title: (
        <Box display="flex" sx={{ marginLeft: "-5px" }}>
          Cyber Operations
        </Box>
      ),
      icon: (
        <MdSpaceDashboard
          color="#000"
          fontWeight="700"
          fontSize="24px"
          style={{ marginLeft: "-3px" }}
        />
      ),
      link: "/task-board",
    },
    {
      title: (
        <Box
          display="flex"
          sx={{ marginLeft: "-5px" }}
          onClick={openFeatureModal}
        >
          <Box pr={4}>Feature Request</Box>
        </Box>
      ),
      icon: (
        <TbFountain
          color="#000"
          fontWeight="700"
          fontSize="24px"
          style={{ marginLeft: "-3px" }}
        />
      ),
    },
  ];

  React.useEffect(() => {
    // Find the matching sidebar item based on the current location
    const matchingItem = Sidebar.find((item) =>
      location.pathname.startsWith(item.link)
    );
    setActiveItem(matchingItem || null); // Provide a default value if matchingItem is undefined
  }, [location.pathname]);

  const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(false);
  const contentRef = React.useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      // Add a margin of 10 pixels to determine "near bottom"
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - 10;
      setIsScrolledToBottom(isNearBottom);
    }
  };

  React.useEffect(() => {
    const ref = contentRef.current;
    if (ref) {
      ref.addEventListener("scroll", handleScroll);
      return () => {
        ref.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <Header handleClose={handleClose} isOpen={isOpen}>
        <Box ml={-3}>
          {Sidebar.map((item, index) => (
            <MenuItem
              key={index}
              onClick={handleCloseUserMenu}
              sx={{
                width: "150px",
                fontWeight: "700",
              }}
            >
              <IconButton aria-label={item.title}>{item.icon}</IconButton>
              <Link
                to={item.link}
                style={{ textDecoration: "none", color: "black" }}
              >
                {item.title}
              </Link>
            </MenuItem>
          ))}
        </Box>
        <Box ml={-3}>
          {settings.map((setting, index) => (
            <MenuItem
              key={index}
              onClick={() => handleItemClick(setting.link)}
              sx={{
                width: "180px",
                fontWeight: "700",
              }}
            >
              <IconButton aria-label={setting.text}>{setting.icon}</IconButton>
              <Link
                to={setting.link}
                style={{ textDecoration: "none", color: "black" }}
              >
                {setting.text}
              </Link>
            </MenuItem>
          ))}
        </Box>
      </Header>
      <AppBar position="fixed" open={open} sx={{ backgroundColor: "black" }}>
        <Toolbar>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <GiHamburgerMenu color="#FED74F" />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: { xs: "none", md: "flex" } }}></Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <TransparentLogo height="70px" />
            </Box>

            <Box sx={{ flexGrow: 0, mt: { xs: 0, md: 2 } }} display="flex">
              <LightTooltip title="Open settings" arrow>
                <Box display="flex">
                  <Box sx={{ zIndex: 100, marginRight: "-14px" }}>
                    {currentPackage === FrontlineDefender && (
                      <PackageAvatar src={Basic} alt="package" />
                    )}
                    {currentPackage === Bunker && (
                      <PackageAvatar src={Standard} alt="package" />
                    )}
                    {currentPackage === FortressPackage && (
                      <PackageAvatar src={Premium} alt="package" />
                    )}
                  </Box>
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{
                      p: 0,
                      marginTop: "-10px",
                    }}
                  >
                    <Avatar src={defaultImg} />
                  </IconButton>
                </Box>
              </LightTooltip>
              <Menu
                sx={{
                  mt: "55px",
                  padding: "0px !important",
                  "&.MuiList-root.MuiList-padding": {
                    padding: "0px !important",
                  },
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleItemClick(setting.link)}
                    sx={{
                      width: "180px",
                      fontWeight: "700",
                      background: Colors.button.primary_bg,
                      paddingTop: "4px !important",
                      paddingBottom: "4px !important",
                    }}
                  >
                    <IconButton aria-label={setting.text}>
                      {setting.icon}
                    </IconButton>
                    <Link
                      to={setting.link}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      {setting.text}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{ display: { xs: "flex", md: "none" }, mt: { xs: 2, md: 0 } }}
            >
              <GiHamburgerMenu color="#FED74F" onClick={handleOpen} />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        open={open}
        sx={{ height: "100%", display: { xs: "none", md: "flex" } }}
      >
        <DrawerHeader style={{ backgroundColor: "black" }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <GiHamburgerMenu color="#000" />
            ) : (
              <RxCrossCircled color="#FED74F" />
            )}
          </IconButton>
        </DrawerHeader>
        <List
          sx={{
            backgroundColor: "black",
            border: "10px solid black",
            height: "100%",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FED74F",
              borderRadius: "20px",
              height: "85%",
            }}
            pt={5}
            pb={5}
          >
            {Sidebar.map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  display: "flex",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: open ? "flex-start" : "center",
                    px: open ? 2 : 0,
                  }}
                  className={
                    activeItem && activeItem.link === item.link
                      ? "active-item"
                      : ""
                  }
                >
                  <Link
                    to={item.link}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <Stack direction="row" spacing={0}>
                      <ListItemIcon
                        sx={{
                          minWidth: open ? 0 : "100%",
                          mr: open ? 2 : "center",
                          ml: open ? 0 : "center",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          mt: 0.5,
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color:
                            activeItem && activeItem.link === item.link
                              ? "#000"
                              : "#000",
                        }}
                      >
                        <Box fontWeight="500"> {item.title}</Box>
                      </ListItemText>
                    </Stack>
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
        style={{
          width: "100% !important",
          overflowX: "hidden",
        }}
      >
        <DrawerHeader />

        <Box
          sx={{
            minHeight: "78vh",
            pb: 4,
            pl: { xs: 3, sm: 3, md: 3 },
            pr: { xs: 3, md: 3 },
          }}
        >
          {children}
        </Box>
        <Box className="footer " display="flex" justifyContent="end">
          <Box onClick={handleModalClick} pr={4}>
            {" "}
            <Button
              startIcon={<MdPrivacyTip style={{ marginRight: "5px" }} />}
              variant="text"
              sx={{ textTransform: "capitalize", color: "#000" }}
            >
              Terms and conditions
            </Button>{" "}
          </Box>
        </Box>
      </Box>

      {/* feature */}

      <Modal
        open={featureModal}
        onClose={handleCloseFeature}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 1300 }}
      >
        <ModalContent sx={featureModalSize}>
          <HeaderModal
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box>
              <Text
                fontSize="24px"
                fontWeight="600"
                color={Colors.text.light_red}
              >
                Feature Request
              </Text>
            </Box>
            <RxCrossCircled
              onClick={handleCloseFeature}
              style={{ cursor: "pointer" }}
              color={Colors.text.black}
              fontSize="25px"
            />
          </HeaderModal>
          <Box pl={4} pr={4}>
            <Box className="laxical_editor">
              <LexicalEditor hasNav={true} closeModal={handleCloseFeature} />
            </Box>
          </Box>
        </ModalContent>
      </Modal>

      {/* footer */}
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent sx={style}>
          <HeaderModal
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box pl={5}>
              <Text
                fontSize="24px"
                fontWeight="600"
                color={Colors.text.light_red}
              >
                Terms And Conditions
              </Text>
            </Box>
            <RxCrossCircled
              onClick={handleModalClose}
              style={{ cursor: "pointer" }}
              color={Colors.text.black}
              fontSize="25px"
            />
          </HeaderModal>
          <div
            ref={contentRef}
            style={{
              maxHeight: "70vh",
              overflowY: "auto",
              padding: "10px 0px",
            }}
            onScroll={handleScroll}
          >
            <TermAndCondition
              disabled={!isScrolledToBottom}
              handleAgree={handleModalClose}
            />
          </div>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default DashboardWrapper;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: 0,
  maxHeight: "90vh",
  paddingBottom: "15px",
  width: "80%",
};

const featureModalSize = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: 0,
  height: "90vh",
  paddingBottom: "10px",
  minWidth: "62%",
  zIndex: 1300,
};
