import React, { useState, useEffect, useCallback } from "react";
import "App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import {
  SignIn,
  SignUp,
  ResetPassword,
  ForgetPassword,
  SupportContact,
  EmailVerification,
  MultiFactorAuthentication,
  AssessmentQuestion,
  AssessmentNistCsfQuestion,
  Profile,
  Dashboard,
  Home,
  StartAssessment,
  SSPCreator,
  NotFound,
  EmailValidate,
  EmailResetPassword,
  CertificatePage,
  Assessment,
  Evidence,
  BufferZone,
  Reconnaissance,
  Intelligence,
  Fortress,
  Tactical,
  BattleReady,
  Inventory,
  Poam,
  Billing,
  ThankuPage,
  InviteUser,
  InviteUserSignUp,
  TrelloCard,
  BufferPhaseTask,
  AssessmentTask,
  AssessmentTaskNist,
  MFAEnablePage,
  AssessmentNistCsf,
  AssessmentLevelTwo,
} from "pages";
import { useSelector } from "react-redux";
import { Loader } from "shared";
import { RootState } from "store";
import { Provider } from "react-redux";
import { store } from "./store";
import PrivateRoute from "./PrivateRoute";
import {
  userPackages,
  userLogout,
  setLogoutTimer,
  clearLogoutTimer,
} from "reducers/user";

import useAppDispatch from "hooks/useAppDispatch";
import { logoutAction, resetAuthToken } from "utils/auth";
import { TIMEOUT_DURATION } from "utils/constant";
import { FrontlineDefender, FortressPackage } from "utils/constant";

function RedirectToPackage() {
  const navigate = useNavigate();

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  useEffect(() => {
    // Redirect to /plan if the package is NOT FortressPackage or is "Null"
    if (currentPackage !== FortressPackage || currentPackage === "Null") {
      navigate("/plan");
    }
  }, [navigate, currentPackage]);

  return null;
}

function RedirectToDashboard() {
  const isVerified = useSelector((state: RootState) => state.user.isVerified);
  const otpEnabled = useSelector((state: RootState) => state.user.otpEnabled);

  const navigate = useNavigate();

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  useEffect(() => {
    if (isVerified && otpEnabled && currentPackage === "Null") {
      navigate("/plan");
    } else if (isVerified && otpEnabled) {
      navigate("/multi-factor-authentication");
    } else if (!otpEnabled && isVerified) {
      navigate("/multi-factor-enable");
      // eslint-disable-next-line no-dupe-else-if
    } else if (!isVerified) {
      navigate("/signin");
    }
  }, [otpEnabled, isVerified, navigate, currentPackage]);

  return null;
}

function RedirectToPackageOne() {
  const navigate = useNavigate();

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  useEffect(() => {
    if (currentPackage === FrontlineDefender || currentPackage === "Null") {
      navigate("/plan");
    }
  }, [navigate, currentPackage]);

  return null;
}

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();

  const logoutTimer = useSelector((state: RootState) => state.user.logoutTimer);

  const handleLogout = React.useCallback(async () => {
    dispatch(userLogout())
      .unwrap()
      .then(() => {
        setTimeout(() => {
          logoutAction();
          resetAuthToken();
        }, 5000); // wait for 5 seconds before executing logoutAction()
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }, [dispatch]);

  const handleActivity = useCallback(() => {
    // Reset the timer if there is user activity
    if (logoutTimer) {
      clearTimeout(logoutTimer);
      dispatch(clearLogoutTimer());
    }
    const timer = setTimeout(() => {
      handleLogout();
      alert("You've been logged out due to inactivity.");
    }, TIMEOUT_DURATION);
    dispatch(setLogoutTimer(timer));
  }, [dispatch, handleLogout, logoutTimer]);

  useEffect(() => {
    // Add event listeners for user activity
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);

    return () => {
      // Remove event listeners on component unmount
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
    };
  }, [handleActivity]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(userPackages())
      .unwrap()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
      });
  }, [dispatch]);

  const activePackage = useSelector(
    (state: RootState) => state.user.userPackage
  );

  const currentPackage =
    activePackage?.length > 0 ? activePackage[0].plan?.name : "Null";

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); // Set loading to false after 1 second
    }, 1000);
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loader isLoading={isLoading} />
      </div>
    );
  }

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<RedirectToDashboard />} />
            <Route
              path="assessment/report/:id"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="start/assessment/:id/level/:pk"
              element={
                <PrivateRoute>
                  <AssessmentQuestion />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="start/assessment/:id"
              element={
                <PrivateRoute>
                  <AssessmentNistCsfQuestion />
                </PrivateRoute>
              }
            ></Route>

            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            ></Route>
            {currentPackage !== "Null" ? (
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              ></Route>
            ) : (
              <Route path="/dashboard" element={<RedirectToDashboard />} />
            )}
            <Route
              path="start/assessment/:id"
              element={
                <PrivateRoute>
                  <StartAssessment />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="ssp/creator/:id"
              element={
                <PrivateRoute>
                  <SSPCreator />
                </PrivateRoute>
              }
            ></Route>
            <Route path="/invite/:date/:string" element={<InviteUser />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/:time/:email" element={<EmailValidate />} />
            <Route path="/signup" element={<SignUp />}></Route>
            <Route path="/forget-password" element={<ForgetPassword />}></Route>
            <Route path="/reset-password" element={<ResetPassword />}></Route>
            <Route path="/contact-support" element={<SupportContact />}></Route>
            <Route
              path="/forgot/:time/:authToken"
              element={<ResetPassword />}
            ></Route>
            <Route
              path="/reset-password-email"
              element={<EmailResetPassword />}
            ></Route>
            <Route
              path="/email-verification"
              element={<EmailVerification />}
            ></Route>
            <Route
              path="/multi-factor-authentication"
              element={<MultiFactorAuthentication />}
            ></Route>

            {currentPackage !== FrontlineDefender &&
            currentPackage !== "Null" ? (
              <Route
                path="/certificate"
                element={
                  <PrivateRoute>
                    <CertificatePage />
                  </PrivateRoute>
                }
              ></Route>
            ) : (
              <Route path="/certificate" element={<RedirectToPackageOne />} />
            )}
            {currentPackage !== FrontlineDefender &&
            currentPackage !== "Null" ? (
              <Route
                path="/assessment/nist-sp-800-171/level/1"
                element={
                  <PrivateRoute>
                    <Assessment />
                  </PrivateRoute>
                }
              ></Route>
            ) : (
              <Route
                path="/assessment/nist-sp-800-171/level/1"
                element={<RedirectToPackageOne />}
              />
            )}

            {currentPackage === FortressPackage ? (
              <Route
                path="/assessment/nist-sp-800-171/level/2"
                element={
                  <PrivateRoute>
                    <AssessmentLevelTwo />
                  </PrivateRoute>
                }
              ></Route>
            ) : (
              <Route
                path="assessment/nist-sp-800-171/level/2"
                element={<RedirectToPackage />}
              />
            )}

            <Route
              path="/assessment/nist-csf"
              element={<AssessmentNistCsf />}
            ></Route>

            {currentPackage !== "Null" ? (
              <Route
                path="inventory"
                element={
                  <PrivateRoute>
                    <Inventory />
                  </PrivateRoute>
                }
              ></Route>
            ) : (
              <Route path="inventory" element={<RedirectToPackageOne />} />
            )}

            <Route
              path="task-board"
              element={
                <PrivateRoute>
                  <TrelloCard />
                </PrivateRoute>
              }
            ></Route>

            <Route
              path="/task/:bufferzone/:objectId"
              element={
                <PrivateRoute>
                  <BufferPhaseTask />
                </PrivateRoute>
              }
            ></Route>

            <Route
              path="/task/assessment/:assessmentID/:levelId"
              element={
                <PrivateRoute>
                  <AssessmentTask />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/task/assessment/:assessmentID"
              element={
                <PrivateRoute>
                  <AssessmentTaskNist />
                </PrivateRoute>
              }
            ></Route>

            {currentPackage !== FrontlineDefender &&
            currentPackage !== "Null" ? (
              <Route
                path="/evidence"
                element={
                  <PrivateRoute>
                    <Evidence />
                  </PrivateRoute>
                }
              ></Route>
            ) : (
              <Route path="evidence" element={<RedirectToPackageOne />} />
            )}
            {currentPackage === FortressPackage ? (
              <Route
                path="/buffer-zone"
                element={
                  <PrivateRoute>
                    <BufferZone />
                  </PrivateRoute>
                }
              ></Route>
            ) : (
              <Route path="buffer-zone" element={<RedirectToPackage />} />
            )}
            {currentPackage === FortressPackage && currentPackage !== "Null" ? (
              <Route
                path="reconnaissance"
                element={
                  <PrivateRoute>
                    <Reconnaissance />
                  </PrivateRoute>
                }
              ></Route>
            ) : (
              <Route path="reconnaissance" element={<RedirectToPackageOne />} />
            )}

            {currentPackage === FortressPackage && currentPackage !== "Null" ? (
              <Route
                path="intelligence"
                element={
                  <PrivateRoute>
                    <Intelligence />
                  </PrivateRoute>
                }
              ></Route>
            ) : (
              <Route path="intelligence" element={<RedirectToPackageOne />} />
            )}
            {currentPackage === FortressPackage && currentPackage !== "Null" ? (
              <Route
                path="Fortress"
                element={
                  <PrivateRoute>
                    <Fortress />
                  </PrivateRoute>
                }
              ></Route>
            ) : (
              <Route path="Fortress" element={<RedirectToPackageOne />} />
            )}

            {currentPackage === FortressPackage && currentPackage !== "Null" ? (
              <Route
                path="tactical"
                element={
                  <PrivateRoute>
                    <Tactical />
                  </PrivateRoute>
                }
              ></Route>
            ) : (
              <Route path="tactical" element={<RedirectToPackageOne />} />
            )}

            {currentPackage === FortressPackage && currentPackage !== "Null" ? (
              <Route
                path="battle-ready"
                element={
                  <PrivateRoute>
                    <BattleReady />
                  </PrivateRoute>
                }
              ></Route>
            ) : (
              <Route path="battle-ready" element={<RedirectToPackageOne />} />
            )}

            <Route
              path="poam/:id"
              element={
                <PrivateRoute>
                  <Poam />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="plan"
              element={
                <PrivateRoute>
                  <Billing />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="multi-factor-enable"
              element={
                <PrivateRoute>
                  <MFAEnablePage />
                </PrivateRoute>
              }
            ></Route>

            <Route
              path="thankyou"
              element={
                <PrivateRoute>
                  <ThankuPage />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <NotFound />
                </PrivateRoute>
              }
            />

            <Route path="/404" element={<NotFound />} />
            <Route path="/sign/up" element={<InviteUserSignUp />} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
