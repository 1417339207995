import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  AssessmentAccordian,
  Text,
  DashboardWrapper,
  TaskAssessmentTable,
  Loader,
  CustomeButton,
} from "shared";
import useAppDispatch from "hooks/useAppDispatch";
import { getAssessmentQaList, setAssessmentId } from "reducers/assessment";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import swal from "sweetalert";
import { Box, Button } from "@mui/material";
import { Colors } from "theme/color";
import { IoChevronBackOutline, IoChevronForward } from "react-icons/io5";
import {
  levelTwoPaginationCount,
  levelTwoPaginationName,
} from "utils/constant";
import { DarkTooltip } from "utils/sharedStyled";

type Props = Record<string, never>;

type QuestionStatus = {
  notMet: boolean;
  met: boolean;
  notInterested: boolean;
};
interface AccordionItem {
  title: string;
  completedQueries: number;
  totalQueries: number;
  securityRequirements: any[];
  userComment: string;
}

const AssessmentPage: FunctionComponent<Props> = () => {
  const assessmentQaList = useSelector(
    (state: RootState) => state.assessment.assessmentsQaList
  );

  const userAnswer = useSelector(
    (state: RootState) => state.assessment.objectives
  );

  const totalObjectives = useSelector(
    (state: RootState) => state.assessment.totalObjectives
  );

  const userResponseLevelTwo = useSelector(
    (state: RootState) => state.assessment.userResponse
  );

  // const userName = useSelector((state: RootState) => state.user.userInfo);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split("/");
  const levelId = parseInt(parts[4]);
  const assessmentId = parts[3];
  const [isLoading, setIsLoading] = useState(true);
  const [assessmentLevelName, setAssessmentLevelName] = useState<any>();
  const [submitDisable, setSubmitDisable] = useState(true);
  const [page, setPage] = useState(1);
  const [accordions, setAccordions] = useState<AccordionItem[]>([]);
  const [apiCalled, setApiCalled] = useState(false);

  const currentAssessmentId = assessmentId;

  const pageNumbers = useSelector(
    (state: RootState) =>
      state.assessment?.assessments[currentAssessmentId as any] || 1
  );

  const activePageNumber = pageNumbers ? pageNumbers : 1;

  const [currentActivePage, setCurrentActivePage] = useState(activePageNumber);

  const [totalResponseLevelTwo, setTotalResponseLevelTwo] = useState<any>();
  useEffect(() => {
    setTotalResponseLevelTwo(userResponseLevelTwo);
  }, [userResponseLevelTwo]);

  const { state } = location;

  const viewFlag = state && state.flage;
  // const assessmentName = state && state.name;

  const phaseCardEmail = state && state.email;

  // levels save and exit function
  const hanldeAssessmentSave = () => {
    navigate("/task-board");
  };

  // level one submit action
  const hanldeSubmitLevelOne = () => {
    navigate("/task-board");
  };

  // level two submit action
  const hanldeSubmitLevelTwo = () => {
    navigate("/task-board");
  };

  // Function to fetch assessment questions on page reload
  const fetchAssessmentQuestions = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        getAssessmentQaList({
          assessmentId: currentAssessmentId,
          levelId,
          page: pageNumbers ? pageNumbers : page,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.success === false && res?.status === 400) {
            swal({
              title: "Assessment",
              text: "Invalid assessment level_id !",
              icon: "error",
              buttons: {
                confirm: {
                  text: "Go Back",
                  value: true,
                  visible: true,
                  className: "btn-primary",
                  closeModal: true,
                },
                cancel: {
                  text: "Cancel",
                  value: null,
                  visible: true,
                  className: "btn-secondary",
                  closeModal: true,
                },
              },
            }).then((value) => {
              if (value) {
                // Redirect to the desired page
                navigate("/task-board");
                window.location.reload();
              }
            });
          }
          console.log(res);
        });
      window.scrollTo(0, 0);
      setIsLoading(false);
      setPage(1);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchAssessmentQuestionsOnscroll = useCallback(
    async (page: any) => {
      if (!apiCalled && levelId === 2) {
        try {
          setIsLoading(true);
          setApiCalled(true);
          await dispatch(
            getAssessmentQaList({
              assessmentId,
              levelId,
              page: page,
            })
          ).unwrap();
        } catch (err) {
          console.error(err);
        } finally {
          setApiCalled(false);
          setIsLoading(false);
        }
      }
    },
    [dispatch, assessmentId, levelId, apiCalled, currentActivePage]
  );

  useEffect(() => {
    fetchAssessmentQuestions();
  }, [dispatch, assessmentId, levelId, currentAssessmentId, pageNumbers]);

  const handleButtonClick = (index: number) => {
    dispatch(
      setAssessmentId({
        assessmentId: assessmentId,
        pageNumber: index + 1,
      })
    );
    setPage(index + 1);
    setCurrentActivePage(index + 1);
    fetchAssessmentQuestionsOnscroll(index + 1);
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    const nextPage = currentActivePage + 1;
    dispatch(
      setAssessmentId({
        assessmentId: assessmentId,
        pageNumber: nextPage,
      })
    );
    fetchAssessmentQuestionsOnscroll(nextPage);
    setCurrentActivePage(nextPage);
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    const nextPage = currentActivePage - 1;
    dispatch(
      setAssessmentId({
        assessmentId: assessmentId,
        pageNumber: nextPage,
      })
    );
    fetchAssessmentQuestionsOnscroll(nextPage);
    setCurrentActivePage(nextPage);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const mappedAccordions = assessmentQaList?.map((item: any) => {
      const securityRequirements = item.security_requirements.map((sr: any) => {
        setAssessmentLevelName(levelId);

        const objectives = sr.objectives.map((objective: any) => {
          const userAnswerValue = userAnswer[objective.pk];
          let status: QuestionStatus = {
            notMet: false,
            met: false,
            notInterested: false,
          };
          if (userAnswerValue === "not_met") {
            status.notMet = true;
          } else if (userAnswerValue === "met") {
            status.met = true;
          } else if (userAnswerValue === "na") {
            status.notInterested = true;
          }

          const question = {
            id: objective.pk,
            assessmentSecurityRequirementId:
              objective.assessment_security_requirement,
            questionNumber: objective.code,
            title: objective.description,
            pkId: objective.pk,
            status,
            isEvidenceProvided: objective.is_evidence_provided,
          };
          return question;
        });
        const controlDescription = sr.control_explanation
          ?.split("\\n")
          .join("\n");
        return {
          controlNumber: sr.control_number,
          controlDescription: controlDescription,
          controlRequirement: sr.control_requirement,
          objectives: objectives,
          userComment: sr.user_comment,
          userResponse: sr.user_response,
          penalty: sr.penalty,
        };
      });

      const accordion = {
        title: item.assessment_family,
        completedQueries: item.total_family_responses,
        totalQueries: item.total_family_objectives,
        securityRequirements: securityRequirements,
        userComment: item.user_comment,
      };
      return accordion;
    });

    setAccordions(mappedAccordions);
  }, [assessmentQaList]);

  const updateCompletedQueries = (index: any, change: any) => {
    setTotalResponseLevelTwo(
      (prevTotalResponseLevelTwo: any) => prevTotalResponseLevelTwo + change
    );
    setAccordions((currentAccordions) => {
      return currentAccordions.map((accordion, idx) => {
        if (idx === index) {
          return {
            ...accordion,
            completedQueries: accordion.completedQueries + change,
          };
        }
        return accordion;
      });
    });
  };

  // this function is maped to get userResponse according to ccurrent assessment
  const completedQueriesArray = accordions.map((item) => {
    return Array.isArray(item.completedQueries)
      ? item.completedQueries
      : [item.completedQueries];
  });

  const userResponse = completedQueriesArray
    .flat()
    .reduce((acc, value) => acc + value, 0);

  // check levelId and userResponse
  useEffect(() => {
    if (levelId === 1) {
      if (userResponse === 59) {
        setSubmitDisable(false);
      } else {
        setSubmitDisable(true);
      }
    } else {
      if (totalResponseLevelTwo === totalObjectives) {
        setSubmitDisable(false);
      } else {
        setSubmitDisable(true);
      }
    }
  }, [levelId, userResponse, totalResponseLevelTwo]);

  const pagination = levelTwoPaginationCount;

  return (
    <DashboardWrapper>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Box sx={{ flexGrow: 1 }} mt={5}>
          <Box>
            <Box mt={3} mb={3} display="flex" justifyContent="space-between">
              <Text fontSize="32px" fontWeight="700" className="text-mobile">
                CMMC Level {assessmentLevelName}
              </Text>
            </Box>
            <Box>
              {accordions?.map((item: any, index: any) => (
                // eslint-disable-next-line react/jsx-key
                <AssessmentAccordian
                  key={index}
                  data={item}
                  index={index}
                  accordions={accordions}
                >
                  <TaskAssessmentTable
                    data={item}
                    index={index}
                    accordions={accordions}
                    assessmentId={assessmentId}
                    levelId={levelId}
                    updateCompletedQueries={(change) =>
                      updateCompletedQueries(index, change)
                    }
                    viewFlag={viewFlag}
                    phaseCardEmail={phaseCardEmail}
                    weightFlag={true}
                  />
                </AssessmentAccordian>
              ))}
            </Box>
          </Box>
          <Box
            mt={4}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <CustomeButton
              title="Save and Exit"
              borderRadius="10px"
              fontWeight="500"
              width="220px"
              align="left"
              onClick={hanldeAssessmentSave}
            />
            {/* {levelId === 2 ? (
              <Box>
                <Button
                  sx={{
                    minWidth: "10px",
                    margin: "0px 4px",
                    color: Colors.text.black,
                  }}
                  onClick={handlePrevious}
                  disabled={currentActivePage === 1 ? true : false}
                >
                  <IoChevronBackOutline />
                </Button>

                {Array.from({ length: pagination }, (_, index) => (
                  <Button
                    onClick={() => handleButtonClick(index)}
                    key={index}
                    variant={
                      currentActivePage === index + 1 ? "contained" : undefined
                    }
                    sx={{
                      minWidth: "10px",
                      maxWidth: "30px",
                      margin: "0px 4px",
                      color: Colors.text.black,
                      fontSize: "14px",
                      backgroundColor:
                        currentActivePage === index + 1
                          ? Colors.button.primary_bg
                          : "",
                    }}
                    size="small"
                  >
                    {index + 1}
                  </Button>
                ))}
                <Button
                  sx={{
                    minWidth: "10px",
                    margin: "0px 4px",
                    color: Colors.text.black,
                  }}
                  onClick={handleNext}
                  disabled={currentActivePage === 14 ? true : false}
                >
                  <IoChevronForward />
                </Button>
              </Box>
            ) : (
              ""
            )} */}
            {levelId === 2 ? (
              <Box>
                <Button
                  sx={{
                    minWidth: "10px",
                    margin: "0px 4px",
                    color: Colors.text.black,
                  }}
                  onClick={handlePrevious}
                  disabled={currentActivePage === 1 ? true : false}
                >
                  <IoChevronBackOutline />
                </Button>

                {Array.from({ length: pagination }, (_, index) => (
                  <DarkTooltip
                    title={levelTwoPaginationName[index]}
                    key={index}
                  >
                    <Button
                      onClick={() => handleButtonClick(index)}
                      variant={
                        currentActivePage === index + 1
                          ? "contained"
                          : undefined
                      }
                      sx={{
                        minWidth: "10px",
                        maxWidth: "30px",
                        margin: "0px 4px",
                        color: Colors.text.black,
                        fontSize: "14px",
                        backgroundColor:
                          currentActivePage === index + 1
                            ? Colors.button.primary_bg
                            : "",
                      }}
                      size="small"
                    >
                      {index + 1}
                    </Button>
                  </DarkTooltip>
                ))}

                <Button
                  sx={{
                    minWidth: "10px",
                    margin: "0px 4px",
                    color: Colors.text.black,
                  }}
                  onClick={handleNext}
                  disabled={currentActivePage === levelTwoPaginationCount}
                >
                  <IoChevronForward />
                </Button>
              </Box>
            ) : (
              ""
            )}

            <CustomeButton
              title="Submit"
              borderRadius="10px"
              fontWeight="500"
              width="100px"
              align="left"
              onClick={
                levelId === 1 ? hanldeSubmitLevelOne : hanldeSubmitLevelTwo
              }
              disabled={submitDisable}
            />
          </Box>
        </Box>
      )}
    </DashboardWrapper>
  );
};

export default AssessmentPage;
