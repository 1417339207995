import React, {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  DashboardWrapper,
  InventoryTable,
  Loader,
  CustomeButton,
  Input,
  Button,
  Logo,
} from "shared";

import useAppDispatch from "hooks/useAppDispatch";
import {
  fetchAssetList,
  addAsset,
  deleteAsset,
  fetchCategory,
} from "reducers/assessment";
import { RootState } from "store";
import { BiPlusCircle } from "react-icons/bi";
import { Grid } from "@mui/material";
import swal from "sweetalert";

import { Box, Modal, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import { Colors } from "theme/color";
import { RxCrossCircled } from "react-icons/rx";

import { useSelector } from "react-redux";
import { LargeModal } from "utils/sharedStyled";
import { ModalContent } from "./style";

const AssesmentSchema = Yup.object().shape({
  assetQuantity: Yup.string()
    .required("Asset Quantity is required")
    .max(50, "Too Long!")
    .matches(/^[0-9]+$/, "Asset Quantity must be a number"),
  assets: Yup.string().required("Asset is required").max(50, "Too Long!"),
});

type Props = Record<string, never>;

const Evidence: FunctionComponent<Props> = () => {
  const paginationRowsPerPageOptions = [5, 10, 15, 20, 25, 50];
  const dispatch = useAppDispatch();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(5);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const selectedObjectIdRef = useRef<number | null>(null);
  const [open, setOpen] = useState(false);

  const [assetCategory, setAssetCategory] = useState("0");
  const [assetType, setAssestType] = useState("0");
  const [searchLoading, setSearchLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setAssetCategory("0");
    setAssestType("0");
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  // select assessment level handle change
  const handleSelectAssetType = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    setAssestType(selectedValue);
  };

  // select assessment type handle
  const handleSelect = (event: SelectChangeEvent) => {
    setAssetCategory(event.target.value);
  };

  const assetListData = useSelector(
    (state: RootState) => state.assessment.assetListData
  );

  const categoryListData = useSelector(
    (state: RootState) => state.assessment.categoryListData
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setTableLoader(true);
    setPage(page);
    dispatch(
      fetchAssetList({
        page: page,
        limit: paginationPerPage,
        search: searchText,
      })
    )
      .unwrap()
      .then((data) => {
        setTimeout(() => {
          setCount(data.count);
          setTableLoader(false);
        }, 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRowsPerPageChange = (event: any) => {
    const value = event?.target?.value;
    setTableLoader(true);
    if (value) {
      setPaginationPerPage(parseInt(value));
      setPage(1);
    }
  };

  const handleClick = () => {
    dispatch(fetchCategory());
    setOpen(true);
  };

  // assessment delete handle
  const handleDelete = useCallback(() => {
    const selectedObjectId = selectedObjectIdRef.current;
    if (selectedObjectId !== null) {
      const objectId = selectedObjectId;
      if (objectId) {
        setTableLoader(true);
        dispatch(deleteAsset({ id: objectId }))
          .unwrap()
          .then(() => {
            // Handle successful deletion
            swal({
              title: "Asset Inventory",
              text: `Asset Inventory Deleted Successfully!`,
              icon: "success",
            });
            setOpenDeleteDialog(false);
            dispatch(
              fetchAssetList({
                page: page,
                limit: paginationPerPage,
                search: searchText,
              })
            )
              .unwrap()
              .then((data) => {
                setCount(data.count);
                setTableLoader(false);
              })
              .catch((err) => {
                if (err.status === 404) {
                  setPage(page - 1);
                  dispatch(
                    fetchAssetList({
                      page: page - 1,
                      limit: paginationPerPage,
                      search: searchText,
                    })
                  )
                    .unwrap()
                    .then((data) => {
                      setCount(data.count);
                      setTableLoader(false);
                    });
                }
                console.log(err);
              });
          })
          .catch((error) => {
            // Handle error - 404 status code in this case
            if (error?.data.status === 500) {
              setTableLoader(false);
              swal({
                title: "Error",
                text: "Internal server error occurred while deleting the ssset inventory.",
                icon: "error",
              });
            } else if (error?.data.status === 404) {
              setTableLoader(false);
              swal({
                title: "Asset Inventory",
                text: `Asset Inventory not found or already deleted.`,
                icon: "error",
              });
            } else {
              // Handle other errors
              swal({
                title: "Error",
                text: "An error occurred while deleting the asset inventory.",
                icon: "error",
              });
            }
          });
      }
    }
  }, [dispatch, setOpenDeleteDialog, page, paginationPerPage]);

  const handleAsset = useCallback(
    async (values: {
      assetQuantity: string;
      assetDescription: string;
      assets: string;
      comment: string;
      controlledBy: string;
      poc: string;
      cuiCategory: string;
      distributionControl: string;
    }) => {
      try {
        if (Number(assetCategory) === 0) {
          swal({
            title: "Asset Inventory",
            text: "Please select Asset Category.",
            icon: "warning",
          });
          return;
        }
        if (Number(assetType) === 0) {
          swal({
            title: "Asset Inventory",
            text: "Please select Asset Type.",
            icon: "warning",
          });
          return;
        }

        const type =
          Number(assetType) === 1
            ? "people"
            : Number(assetType) === 2
            ? "technology"
            : Number(assetType) === 3
            ? "facilities"
            : "people";
        setTableLoader(true);
        await dispatch(
          addAsset({
            category: assetCategory,
            asset: values.assets,
            quantity: values.assetQuantity,
            assetType: type,
            assetDescription: values.assetDescription,
            comment: values.comment,
            controlledBy: values.controlledBy,
            poc: values.poc,
            cuiCategory: values.cuiCategory,
            distributionControl: values.distributionControl,
          })
        )
          .unwrap()
          .then(() => {
            swal({
              title: "Asset Inventory",
              text: "Asset Inventory Added Successfully !",
              icon: "success",
            });
            setOpen(false);
            setAssetCategory("0");
            setAssestType("0");
            dispatch(
              fetchAssetList({
                page: 1,
                limit: paginationPerPage,
                search: searchText,
              })
            )
              .unwrap()
              .then((data) => {
                setCount(data.count);
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } catch (error: any) {
        if (error?.data.status === 400) {
          swal({
            title: "Asset Inventory",
            text: "something went wrong",
            icon: "error",
          });
        } else {
          swal({
            title: "Asset Inventory",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      } finally {
        setTableLoader(false);
      }
    },
    [dispatch, page, paginationPerPage, assetCategory, assetType]
  );

  useEffect(() => {
    setSearchLoading(true);
    setPage(1);
    dispatch(
      fetchAssetList({
        page: 1,
        limit: paginationPerPage,
        search: searchText,
      })
    )
      .unwrap()
      .then((data) => {
        setTimeout(() => {
          setSearchLoading(false);
          setCount(data.count);
          setLoading(false);
          setTableLoader(false);
        }, 0);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, paginationPerPage, searchText]);

  const assetData = assetListData?.map((item: any, index) => {
    const assessment = {
      index: index + 1,
      id: item.pk,
      category: item.category.category_name,
      asset: item.asset,
      assetQuantity: item.quantity,
      assetType: item.asset_type,
      description: item.asset_description,
      comment: item.comment,
      controlledBy: item.controlled_by,
      poc: item.poc,
      cuiCategory: item.cui_category,
      distributionControl: item.distribution_control,
    };
    return assessment;
  });

  return (
    <DashboardWrapper>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Box sx={{ flexGrow: 1 }} mt={5}>
          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box>
                <CustomeButton
                  title={"Add Asset Inventory"}
                  borderRadius="10px"
                  fontWeight="500"
                  fontSize="15px"
                  align="right"
                  width="250px"
                  Icon={<BiPlusCircle />}
                  onClick={handleClick}
                />
              </Box>
            </Grid>
          </Grid>

          {tableLoader ? (
            <Loader isLoading={tableLoader} />
          ) : (
            <InventoryTable
              evidence={assetData}
              totalRows={count}
              paginationRowsPerPageOptions={paginationRowsPerPageOptions}
              pages={page}
              paginationPerPages={paginationPerPage}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
              setPage={setPage}
              handleSearch={handleInputChange}
              handleDelete={handleDelete}
              searchTexts={searchText}
              openDeleteDialogs={openDeleteDialog}
              setOpenDeleteDialog={setOpenDeleteDialog}
              selectedObjectIdRef={selectedObjectIdRef}
              loading={tableLoader}
              searchLoading={searchLoading}
            />
          )}
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent sx={LargeModal}>
          <Box display="flex" justifyContent="end" pt={1} pr={1}>
            <RxCrossCircled
              onClick={handleClose}
              style={{ cursor: "pointer" }}
              color={Colors.text.black}
              fontSize="25px"
            />
          </Box>

          <Box>
            <Logo />
          </Box>

          <Box m={4}>
            <Box>
              <Formik
                initialValues={{
                  assetQuantity: "",
                  assetDescription: "",
                  assets: "",
                  comment: "",
                  controlledBy: "",
                  poc: "",
                  cuiCategory: "",
                  distributionControl: "",
                }}
                validationSchema={AssesmentSchema}
                onSubmit={handleAsset}
              >
                {({ errors, touched, values, handleChange, handleSubmit }) => (
                  <Box>
                    <Box>
                      <Box p={{ sx: 1, md: 1, lg: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box pt={2}>
                              <Select
                                labelId="demo-select-small"
                                name="assessmentType"
                                id="demo-select-small"
                                value={assetCategory}
                                placeholder="Select"
                                onChange={handleSelect}
                                sx={{
                                  "&.MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "transparent !important", // Hide the root border
                                    },
                                    "&:hover fieldset, &.Mui-focused fieldset":
                                      {
                                        borderColor: "transparent !important", // Hide the border on hover and focus
                                      },
                                  },
                                  "& .MuiSelect-icon": {
                                    color: "#FED74F",
                                    border: "1px solid #FED74F",
                                    borderRadius: "50px",
                                  },
                                  "& .MuiSelect-select": {
                                    borderColor: "#FED74F !important",
                                    border: "2px solid #FED74F !important",
                                    borderRadius: "50px !important",
                                    padding: "20px 20px",
                                    "&:hover, &:focus": {
                                      borderColor: "#FED74F !important",
                                      border: "2px solid #FED74F !important",
                                    },
                                  },
                                  width: { xs: "100%", md: "100%" },
                                }}
                              >
                                <MenuItem
                                  value="0"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  Asset category
                                </MenuItem>
                                {categoryListData?.map((category) => (
                                  <MenuItem
                                    key={category.pk}
                                    value={category.pk}
                                  >
                                    {category.category_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box>
                              <Input
                                name="assets"
                                type="text"
                                placeholder="Asset"
                                value={values.assets}
                                onChange={handleChange("assets")}
                              />
                              {errors.assets && touched.assets ? (
                                <div className="error-message">
                                  {errors.assets}
                                </div>
                              ) : null}
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box>
                              <Input
                                name="assetQuantity"
                                type="text"
                                placeholder="Asset Quantity"
                                value={values.assetQuantity}
                                onChange={handleChange("assetQuantity")}
                              />
                              {errors.assetQuantity && touched.assetQuantity ? (
                                <div className="error-message">
                                  {errors.assetQuantity}
                                </div>
                              ) : null}
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box pt={2}>
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={assetType}
                                onChange={handleSelectAssetType}
                                sx={{
                                  "&.MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "transparent !important", // Hide the root border
                                    },
                                    "&:hover fieldset, &.Mui-focused fieldset":
                                      {
                                        borderColor: "transparent !important", // Hide the border on hover and focus
                                      },
                                  },
                                  "& .MuiSelect-icon": {
                                    color: "#FED74F",
                                    border: "1px solid #FED74F",
                                    borderRadius: "50px",
                                  },
                                  "& .MuiSelect-select": {
                                    borderColor: "#FED74F !important",
                                    border: "2px solid #FED74F !important",
                                    borderRadius: "50px !important",
                                    padding: "20px 20px",
                                    "&:hover, &:focus": {
                                      borderColor: "#FED74F !important",
                                      border: "2px solid #FED74F !important",
                                    },
                                  },
                                  width: { xs: "100%", md: "100%" },
                                }}
                              >
                                <MenuItem
                                  value="0"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  Asset Type
                                </MenuItem>
                                <MenuItem
                                  value="1"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  People
                                </MenuItem>
                                <MenuItem
                                  value="2"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  Technology
                                </MenuItem>
                                <MenuItem
                                  value="3"
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: Colors.button.primary_bg,
                                    },
                                  }}
                                >
                                  Facility
                                </MenuItem>
                              </Select>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box>
                              <Input
                                name="assetDescription"
                                type="text"
                                placeholder="Asset Description"
                                value={values.assetDescription}
                                onChange={handleChange("assetDescription")}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box>
                              <Box>
                                <Input
                                  name="comment"
                                  type="text"
                                  placeholder="Comment (Optional)"
                                  value={values.comment}
                                  onChange={handleChange("comment")}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            {Number(assetCategory) == 1 ? (
                              <>
                                <Box>
                                  <Input
                                    name="controlledBy"
                                    type="text"
                                    placeholder="Controlled By"
                                    value={values.controlledBy}
                                    onChange={handleChange("controlledBy")}
                                  />
                                </Box>
                              </>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            {Number(assetCategory) == 1 ? (
                              <>
                                {" "}
                                <Box>
                                  <Input
                                    name="cuiCategory"
                                    type="text"
                                    placeholder="Cui Category"
                                    value={values.cuiCategory}
                                    onChange={handleChange("cuiCategory")}
                                  />
                                </Box>
                              </>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            {Number(assetCategory) == 1 ? (
                              <Box>
                                <Input
                                  name="poc"
                                  type="text"
                                  placeholder="Poc"
                                  value={values.poc}
                                  onChange={handleChange("poc")}
                                />
                              </Box>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            {Number(assetCategory) == 1 ? (
                              <Box>
                                <Input
                                  name="distributionControl"
                                  type="text"
                                  placeholder="Distribution Control"
                                  value={values.distributionControl}
                                  onChange={handleChange("distributionControl")}
                                />
                              </Box>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Box pt={3}>
                      <Button
                        title={"Save"}
                        borderRadius="10px"
                        fontWeight="500"
                        fontSize="18px"
                        align="center"
                        onClick={handleSubmit}
                        width="100px"
                      />
                    </Box>
                  </Box>
                )}
              </Formik>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    </DashboardWrapper>
  );
};

export default Evidence;
