import React, { FunctionComponent, useCallback, useState } from "react";
import { Text, Button, Card, Logo, Input } from "shared";
import { Colors } from "theme/color";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Modal,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import useAppDispatch from "hooks/useAppDispatch";
import { userSignUp } from "reducers/user";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import { experimentalStyled as styled } from "@mui/material/styles";
import { RxCrossCircled } from "react-icons/rx";
import { TermAndCondition } from "pages";

const SignUpSchema = yup.object().shape({
  name: yup
    .string()
    .matches(
      /^[A-Za-z ]+$/,
      "Only alphabetic characters and spaces are allowed"
    )
    .required("First name is required")
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
  lname: yup
    .string()
    .matches(
      /^[A-Za-z ]+$/,
      "Only alphabetic characters and spaces are allowed"
    )
    .required("Last name is required")
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
  password: yup
    .string()
    .required("Password is required")
    .min(
      8,
      "Password must contain at least one letter, one number, one special character and at least 8 characters"
    )
    .max(40, "Password must not exceed 40 characters")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/,
      "Password must contain at least one letter, one number, and one special character"
    ),

  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Confirm Password does not match"),
  email: yup.string().email("Invalid email").required("Email is required"),
  companyName: yup
    .string()
    .matches(
      /^[A-Za-z ]+$/,
      "Only alphabetic characters and spaces are allowed"
    )
    .required("Company name is required")
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
});

export const ModalContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  width: 500,
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

export const Header = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: theme.spacing(2),
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
}));

type Props = Record<string, never>;

const SignUp: FunctionComponent<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const siteKey = process.env.REACT_APP_SITE_KEY!;
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setIsScrolledToBottom(false);
    setOpen(true);
  };

  const handleAgree = () => {
    setIsChecked(true);
    setOpen(false);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };
  const handleSignUp = useCallback(
    async (values: {
      name: string;
      lname: string;
      email: string;
      password: string;
      companyName: string;
    }) => {
      try {
        if (
          !values.email ||
          !values.name.trim() ||
          !values.lname.trim() ||
          !values.companyName.trim()
        ) {
          swal({
            title: "Register",
            text: "Please ensure all fields are filled out correctly",
            icon: "warning",
          });
          return; // Exit function if any field is empty
        }

        if (isChecked === false) {
          swal({
            title: "Register",
            text: "Kindly agree to the terms and conditions to proceed",
            icon: "warning",
          });
          return;
        }

        const captchaValue = recaptchaRef.current?.getValue();
        if (!captchaValue) {
          swal({
            title: "Register",
            text: "Please verify the reCAPTCHA!",
            icon: "warning",
          });
          return; // Exit function if any field is empty
        }

        setIsLoading(true);
        setIsDisabled(true);
        // attempt a login, and if successful get the user profile
        await dispatch(
          userSignUp({
            name: values.name + " " + values.lname,
            email: values.email,
            password: values.password,
            companyName: values.companyName,
            toc_agree: isChecked,
          })
        ).unwrap();
        // go to the main page
        swal({
          title: "Register",
          text: "Your email has been sent successfully!",
          icon: "success",
        });
        navigate("/email-verification");
      } catch (error: any) {
        if (error?.data.status === 400) {
          swal({
            title: "Register",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        } else if (error?.data.status === 401) {
          swal({
            title: "Register",
            text: "Email Already Exist",
            icon: "error",
          });
        } else {
          swal({
            title: "Register",
            text: "Oops, something went wrong. Please try again later.",
            icon: "error",
          });
        }
      } finally {
        setIsLoading(false);
        setIsDisabled(false);
      }
    },
    [dispatch, isChecked]
  );

  const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(false);
  const contentRef = React.useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      // Add a margin of 10 pixels to determine "near bottom"
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - 10;
      setIsScrolledToBottom(isNearBottom);
    }
  };

  React.useEffect(() => {
    const ref = contentRef.current;
    if (ref) {
      ref.addEventListener("scroll", handleScroll);
      return () => {
        ref.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} className="background-image">
            <Box
              p={{ xs: 2, sm: 2, md: 0, lg: 4 }}
              pl={{ lg: 8, md: 6 }}
              pr={{ lg: 8, md: 6 }}
            >
              <Card>
                <Box p={{ xs: 2, sm: 2, md: 6, lg: 8 }}>
                  <Logo />
                  <Box pt={8}>
                    <Formik
                      initialValues={{
                        name: "",
                        password: "",
                        confirmPassword: "",
                        email: "",
                        companyName: "",
                        lname: "",
                      }}
                      validationSchema={SignUpSchema}
                      onSubmit={handleSignUp}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <Box>
                          <Input
                            name="name"
                            type="text"
                            placeholder="First Name"
                            value={values.name}
                            onChange={handleChange("name")}
                          />
                          {errors.name && touched.name ? (
                            <div className="error-message">{errors.name}</div>
                          ) : null}
                          <Input
                            name="lname"
                            type="text"
                            placeholder="Last Name"
                            value={values.lname}
                            onChange={handleChange("lname")}
                          />
                          {errors.lname && touched.lname ? (
                            <div className="error-message">{errors.lname}</div>
                          ) : null}
                          <Input
                            name="companyName"
                            type="text"
                            placeholder="Company Name"
                            value={values.companyName}
                            onChange={handleChange("companyName")}
                          />
                          {errors.companyName && touched.companyName ? (
                            <div className="error-message">
                              {errors.companyName}
                            </div>
                          ) : null}
                          <Input
                            name="email"
                            type="email"
                            placeholder="Company Email"
                            value={values.email}
                            onChange={handleChange("email")}
                          />
                          {errors.email && touched.email ? (
                            <div className="error-message">{errors.email}</div>
                          ) : null}
                          <Input
                            name="password"
                            placeholder="Password"
                            type={showPassword ? "text" : "password"}
                            value={values.password}
                            onChange={handleChange("password")}
                            handleShow={toggleShowPassword}
                            Icon={
                              showPassword ? <Visibility /> : <VisibilityOff />
                            }
                          />
                          {errors.password && touched.password ? (
                            <div className="error-message">
                              {errors.password}
                            </div>
                          ) : null}
                          <Input
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            type={showConfirmPassword ? "text" : "password"}
                            value={values.confirmPassword}
                            onChange={handleChange("confirmPassword")}
                            handleShow={toggleShowConfirmPassword}
                            Icon={
                              showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )
                            }
                          />
                          {errors.confirmPassword && touched.confirmPassword ? (
                            <div className="error-message">
                              {errors.confirmPassword}
                            </div>
                          ) : null}
                          <Box mt={1} display="flex" justifyContent="center">
                            <FormControlLabel
                              onClick={handleClick}
                              value="end"
                              control={<Checkbox checked={isChecked} />}
                              label="I accept terms and conditions"
                              labelPlacement="end"
                            />
                          </Box>
                          <Box
                            mt={2}
                            display="flex"
                            justifyContent="center"
                            width="100%"
                          >
                            <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} />
                          </Box>

                          <Box pt={4}>
                            <Button
                              title={isLoading ? "Loading..." : "Sign Up"}
                              align="center"
                              onClick={handleSubmit}
                              disabled={isDisabled}
                            />
                          </Box>
                        </Box>
                      )}
                    </Formik>
                    <Box
                      pt={2}
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      <Link
                        to="/"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Stack
                          direction={{ xs: "row", sm: "row" }}
                          spacing={{ xs: 0, sm: 0, md: 0 }}
                        >
                          <Box color={Colors.text.black}>
                            Already have an account?{" "}
                            <Typography component="span" className="creat-link">
                              Log In
                            </Typography>
                          </Box>
                        </Stack>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} display={{ xs: "none", lg: "block" }}>
            <Box pt={5} pb={2}>
              <Box>
                <Text
                  fontSize="40px"
                  fontWeight="700"
                  align="center"
                  variant="h3"
                >
                  Get CMMC Compliant ASAP
                </Text>
              </Box>
              <Box pl={10} pr={10} pt={4}>
                <Text fontSize="20px" fontWeight="400">
                  With CMMC Proof, you&rsquo;re not just adopting a compliance
                  tool; <br /> you&rsquo;re gaining a strategic ally to guide
                  you through the CMMC compliance maze with unmatched expertise.
                  Build trust and drive growth with CMMC Proof—your shield
                  against compliance complexity.
                </Text>
              </Box>
              <Box pl={10} mt={5}>
                <Button title="Learn More" align="left" disabled={true} />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalContent sx={style}>
            <Header
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box pl={5}>
                <Text
                  fontSize="24px"
                  fontWeight="600"
                  color={Colors.text.light_red}
                >
                  Terms And Conditions
                </Text>
              </Box>
              <RxCrossCircled
                onClick={handleClose}
                style={{ cursor: "pointer" }}
                color={Colors.text.black}
                fontSize="25px"
              />
            </Header>
            <div
              ref={contentRef}
              style={{
                maxHeight: "70vh",
                overflowY: "auto",
                padding: "10px 0px",
              }}
              onScroll={handleScroll}
            >
              <TermAndCondition
                disabled={!isScrolledToBottom}
                handleAgree={handleAgree}
              />
            </div>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default SignUp;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: 0,
  maxHeight: "90vh",
  paddingBottom: "15px",
  width: "80%",
};
